import { useState, useEffect } from 'react'
import { functions } from '../HttpFunctions'

export default function useAccountValidation({ user, shopInfo }) {
    const [res, setRes] = useState(false)

    async function getResults() {
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shopId: user.uid
            })
        };


        try {
            const api = await fetch(`${functions}/app/check_account_is_valid`, params);
            const response = await api.json()

            setRes(response)


        }
        catch (error) {
            console.log(error)
            if (error.name === 'TypeError') {
                console.log('There was a network error.');
            }
            return error
        }
    }

    useEffect(() => {
        getResults()
    }, [user, shopInfo])

    return res
}
