import React, { useState } from 'react'
import styled from 'styled-components';
import { ChevronRightRounded as RightChevron } from "@material-ui/icons"
import { PrimaryBtn, Input, Message, Checkbox } from 'zyppd-bits'
import firebase from '../firebase'
import 'firebase/compat/auth'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";


import { marketing_url } from '../HttpFunctions'

export default function CreateAccount({ setState }) {

    const auth = getAuth();

    const [termsAgreed, setTermsAgreed] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [emailAndPassword, setEmailAndPassword] = useState({
        email: { value: '', isValidated: '' },
        password: { value: '', isValidated: '' },
        repeat_password: { value: '', isValidated: '' },
    })

    const handleInput = (e, isValidated) => {

        setInputError(false);
        setEmailAndPassword({
            ...emailAndPassword,
            [e.target.name]: {
                value: e.target.value,
                isValidated: isValidated
            }
        })

    }


    const signUp = () => {

        if (!emailAndPassword.email.isValidated) return setInputError("Please enter a valid email address");
        if (emailAndPassword.password.value.length <= 5) return setInputError("Your password must be at least 6 characters long");
        if (emailAndPassword.password.value !== emailAndPassword.repeat_password.value) return setInputError("Please make sure your passwords match");

        // firebase.auth().
        createUserWithEmailAndPassword(auth, emailAndPassword.email.value, emailAndPassword.password.value)
            .then((userCredential) => {
                // Signed in
                setEmailAndPassword(false);
                // ...
            })
            .catch((error) => {
                setInputError(error.message);
            });
        // createUserWithEmailAndPassword(auth, emailAndPassword.email.value, emailAndPassword.password.value)
        //     .then((userCredential) => {
        //         // Signed in 
        //         const user = userCredential.user;
        //         setEmailAndPassword(false);
        //         // ...
        //     })
        //     .catch((error) => {
        //         setInputError(error.message);
        //     });
    }



    return (
        <>
            <>
                <Input
                    type="email"
                    placeholder="email@example.com"
                    name="email"
                    message={'Enter your email'}
                    handleInput={handleInput}
                    validationNeeded={true}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    message={'Enter your password'}
                    handleInput={handleInput}
                    validationNeeded={true}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    name="repeat_password"
                    message={'Repeat Password'}
                    handleInput={handleInput}
                    validationNeeded={true}
                />
                {inputError && (
                    <>
                        <Message fullWidth style={{ marginTop: '.5em' }} type="negative">{inputError}</Message>
                        <p
                            style={{ margin: '.5em' }}
                            onClick={() => setState(prevState => !prevState)}>
                            Sign in instead?
                        </p>
                    </>
                )
                }
                <TermsAndConditions
                    style={{
                        margin: '1.5em 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        onClick={(e) => setTermsAgreed(!termsAgreed)}
                        checked={termsAgreed}
                        name='terms_and_conditions'
                    />
                    <div
                        style={{
                            position: 'relative',
                            top: '-.5em',
                            maxWidth: '35ch',
                        }}
                    >
                        <small>I have read and agree to the <a href={`${marketing_url}/legal/terms_and_conditions`} target="_blank" rel="noopener noreferrer">terms and conditions</a> and <a href={`${marketing_url}/legal/privacy_policy`} target="_blank" rel="noopener noreferrer">privacy policy</a></small>
                    </div>
                </TermsAndConditions>
                <PrimaryBtn
                    fullWidth
                    style={{
                        margin: '1em 0'
                    }}
                    onClick={() => signUp()}
                    disabled={!termsAgreed}
                >
                    Sign Up
                    <RightChevron />
                </PrimaryBtn>
            </>


        </>
    )
}

const TermsAndConditions = styled.div`
    a {
        text-decoration: underline;
    }
`