import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import { Group, PrimaryBtn, Modal } from 'zyppd-bits'
import { PriorityHighRounded as WarningIcon } from '@material-ui/icons'
import NeedsVerification from '../../Components/NeedsVerfication'
import toast from 'react-hot-toast'

export default function DisableShops({ user, shopInfo, accountValidation }) {

    const disabled = shopInfo.disabled || false;
    const db = firebase.firestore()

    const [modal, setModal] = useState(false)

    function handleDisable() {

        toast.promise(
            db.collection(`shops`).doc(user.uid).update({
                disabled: !disabled || false
            }),
            {
                loading: "Updating...",
                success: !disabled ? 'Shop has been disabled' : 'Shop is now active',
                error: 'Sorry, something went wrong'
            }
        )
        setModal(false)
    }

    return (
        <>
            <Group >
                <div
                    style={{
                        display: 'flex',
                        margin: '0 0 1em 0'
                    }}
                >
                    {disabled && <WarningIcon type="warning" />
                    }
                    <h3>
                        {!disabled ?
                            'Your shop is active'
                            : 'Your shop is offline'
                        }
                    </h3>
                </div>
                <div
                    style={{ maxWidth: '65ch', marginBottom: '1em' }}
                >
                    <p>
                        {!disabled ?
                            'This will disable your shop and prevent any bookings being made, you can re-enable your shop anytime.'
                            : 'Enable your shop to start taking bookings'
                        }
                    </p>
                    <br />
                    <p>You always maintain access to your zyppd.in admin account.</p>
                </div>

                {!disabled ?
                    <PrimaryBtn type="negative" ullWidth={true} onClick={() => setModal(!modal)}>Disable Shop</PrimaryBtn>
                    :
                    <PrimaryBtn type="warning" fullWidth={true} onClick={() => setModal(!modal)}>Enable Shop</PrimaryBtn>
                }

                <Modal
                    isVisible={modal}
                    shade={true}
                    close={() => setModal(false)}
                    title={`Are you sure you wish to ${!disabled ? 'disable' : 'enable'} your shop?`}
                >
                    <NeedsVerification>
                        <PrimaryBtn
                            type={!disabled ? 'negative' : 'positive'}
                            // type="warning"
                            fullWidth={true}
                            onClick={() => {
                                handleDisable()
                            }}
                        >
                            {!disabled ? 'Disable' : 'Enable'} Shop
                        </PrimaryBtn>
                    </NeedsVerification>

                </Modal>
            </Group>
        </>
    )
}