import React from 'react'
import { Group, H3 } from 'zyppd-bits'
import styled from 'styled-components'

export default function AccountDetails({ user, shopInfo, businessInfo }) {

    return (
        <>
            <H3 style={{ marginBottom: '.5em' }}>Account Details</H3>
            <Group>
                <UL>
                    <li>
                        membership: <strong>{shopInfo.membership}</strong>
                    </li>
                    <li>
                        shop status: <strong>{shopInfo.active && !shopInfo.disabled ? 'active' : 'inactive'}</strong>
                    </li>
                    <li>
                        shop url: <strong>{businessInfo.domain_name}/{shopInfo.url}</strong>
                    </li>
                    <li>
                        shop id: <strong>{shopInfo.publicId}</strong>
                    </li>
                    <li>
                        email: <strong>{shopInfo.email}</strong>
                    </li>
                </UL>
            </Group>
        </>
    )
}

const UL = styled.ul`
    li {
        margin: .5em 0;
    }
`