
export const AnimationContainer = {
    hidden: {
        x: '-100%',
        transition: {
            type: 'tween',
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    show: {
        x: '0',
        // opacity: 1,
        transition: {
            type: 'tween',
            staggerChildren: 0.1
        }
    }
}

export const AnimationItem = {
    show: {
        x: 0,
        opacity: 1,
        transition: {
            stiffness: 1000, velocity: -100
        }
    },
    hidden: {
        x: -50,
        opacity: 0,
        transition: {
            stiffness: 1000
        }
    }

}
