import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Group, SubtleListItem, Modal, Input, Checkbox, PrimaryBtn, SecondaryBtn, Select, TextArea, Message, useCheck, Notification, H2 } from 'zyppd-bits'
import { AddRounded as EditIcon } from '@material-ui/icons'
import { CloseRounded as Cross, AddRounded as PlusIcon } from '@material-ui/icons'
import { priceToString } from '../../../../Helpers/priceToString'
import { Link } from 'react-router-dom'
import createUID from '../../../../Helpers/CreateUID'
import firebase from 'firebase/compat/app'
import toast from 'react-hot-toast';

export default function Services({ shopInfo, user }) {

    const db = firebase.firestore()
    const { requiresCheck } = useCheck()
    const { services, staff } = shopInfo

    const [chosenService, setServiceModalIsVisible] = useState(false)
    const [error, setError] = useState(false)
    const [awaiting, setAwaiting] = useState(false)
    const [newService, setNewService] = useState({})


    const [timeLengths] = useState(() => Array.from({ length: 9 }, (v, t) => {
        t = t + 1
        return {
            value: t,
            label: `${shopInfo.timeSlotLength * t} minutes`,
            name: `amountOfTime`,
        }
    }))


    function handleChange(e) {
        setError(false)
        e = e.target || e

        if (e.name === 'price' || e.name === 'price-range-start' || e.name === 'price-range-end') {
            if (e.value.length <= 2) e.value = e.value + '.00'
            e.value = Number(e.value)
            e.value = e.value * 100
        }


        setNewService(prevState => {
            return {
                ...prevState,
                [e.name]: e.value
            }
        })
    }

    function handleCustomerPaysFeeChange(e) {
        db.collection('shops').doc(user.uid)
            .update({ customerPaysFee: e.value })
            .then(() => {
                toast.success(`Fee payment has been updated`)
            })
            .catch((err) => {
                toast.error(`There was an error performing this update`)
            })
    }


    useEffect(() => {

        setNewService(prevState => {
            return {
                ...prevState,
                ...chosenService
            }
        })
    }, [chosenService])

    function updateService(serviceToUpdate) {

        let maxPrice = Number(newService['price-range-end'])

        let priceRange = newService.deposit ?
            [Number(newService['price-range-start']) || (newService.priceRange && Number(newService.priceRange[0])) || false,
            !isNaN(maxPrice) ? maxPrice : (newService.priceRange && Number(newService.priceRange[1])) || false]
            : [false, false]

        if (priceRange[1] === 0) priceRange[1] = false;

        const newServ = {
            title: newService.title,
            description: newService.description ? newService.description : '',
            amountOfTime: newService.amountOfTime,
            priceRange,
            price: Number(newService.price),
            deposit: newService.deposit ? newService.deposit : false
        }


        const newServices = serviceToUpdate.id ? services.map(service => {
            return service.id === serviceToUpdate.id ? { ...service, ...newServ } : { ...service }
        }) : [...services, { ...newServ, id: createUID(10) }]

        if (!newServ.title) return setError("A service title is required")
        if (!newServ.amountOfTime) return setError("A service length is required")
        if (!newServ.price) return setError("A price is required")
        // if (newServ.price < 300) return setError("Service price must be at least £3");
        if (newServ.deposit === true && newServ.priceRange[0] === false) return setError("A starting price is required if you are taking a deposit")
        if (newServ.deposit === true && newServ.priceRange[1] !== false && newServ.priceRange[0] > newServ.priceRange[1]) return setError("The starting price must be smaller than the max price")

        // setAwaiting(true)

        toast.promise(
            db.collection('shops').doc(user.uid)
                .update({ services: newServices }),
            {
                loading: 'Updating service...',
                success: <b>Service updated</b>,
                error: <b>Sorry, something must have gone wrong</b>
            }
        )

        setAwaiting(false)
        setServiceModalIsVisible(false)

    }

    function handleRemove(chosenService) {

        const withDeletedService = services.map(service => {
            return service.id === chosenService.id ?
                {
                    ...service,
                    disabled: true
                } : { ...service }
        })

        toast.promise(
            db.collection('shops').doc(user.uid)
                .update({ services: withDeletedService }),
            {
                loading: 'Removing service...',
                success: <b>Service removed</b>,
                error: <b>There was an error remove this service</b>
            }

        )

        setServiceModalIsVisible(false)
    }

    return (
        <>
            <H2>Services</H2>
            <Group
            >
                <div
                    style={{
                        paddingTop: '.5em'
                    }}
                >
                    <Checkbox
                        label="Customer pays the booking fee"
                        onClick={(e) => handleCustomerPaysFeeChange({ name: 'customerPaysFee', value: !shopInfo.customerPaysFee })}
                        checked={shopInfo.customerPaysFee ?? true}
                        name='deposit'
                    />
                </div>

            </Group>
            <Group>
                <UL>
                    {services && services.filter(service => service.disabled !== true).map(service => {
                        return (


                            <SubtleListItem
                                className="service-item"
                                key={service.id}
                                style={{
                                    position: 'relative',
                                    zIndex: '1'
                                }}
                            >
                                {staff.every(member => !member.services.includes(service.id)) &&
                                    staff.some(member => !member.services.includes(service.id)) &&
                                    <Link to="/staff"
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            left: '0'
                                        }}
                                    >
                                        <Notification
                                            type="negative"
                                            message={`${service.title} has no staff, click here to edit staff members`}
                                        />
                                    </Link>
                                }
                                <div>
                                    <small>Title</small>
                                    <h3>
                                        {service.title}
                                    </h3>
                                </div>
                                <div>
                                    <small>{service.deposit ? 'Deposit Amount' : 'Price'}</small>
                                    <p>
                                        £{priceToString(service.price)}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setServiceModalIsVisible(service)}
                                >
                                    <EditIcon style={{ marginRight: '0' }} />
                                </div>
                            </SubtleListItem>

                        )
                    })}
                </UL>

                <Modal
                    isVisible={chosenService}
                    close={() => (setServiceModalIsVisible(false))}
                    shade={true}
                    style={{ overflow: 'scroll' }}
                    title="New Service"
                >
                    {chosenService &&
                        <>
                            <Input
                                type="text"
                                value={chosenService.title}
                                name="title"
                                message={'Service Name'}
                                placeholder="Service Name"
                                validationNeeded={false}
                                handleInput={handleChange}
                            />
                            <TextArea
                                type="text"
                                value={chosenService.description}
                                name="description"
                                message={'Service Description'}
                                placeholder="Service Description"
                                validationNeeded={false}
                                handleInput={handleChange}
                            />
                            <div
                                style={{
                                    position: 'relative',
                                    zIndex: '3'
                                }}
                            >
                                <Select
                                    message={'Service Length'}
                                    options={timeLengths}
                                    defaultValue={timeLengths[timeLengths.findIndex(i => chosenService.amountOfTime === i.value)]}
                                    onChange={handleChange}
                                    isSearchable={false}
                                />
                            </div>
                            {/* <Group> */}
                            <div
                                style={{
                                    margin: '2em 0 1em 0'
                                }}
                            >
                                <Checkbox
                                    label="Receive a deposit for this service instead of full payment"
                                    onClick={(e) => handleChange({ name: 'deposit', value: !newService.deposit })}
                                    checked={!!newService.deposit}
                                    name='deposit'
                                />
                            </div>
                            <div style={{
                                // display: 'flex',
                                // gap: '1em',
                                margin: '.5em 0 1em 0'
                            }}>
                                <Input
                                    message={`${!!newService.deposit ? 'Deposit' : 'Price'} (in GBP)`}
                                    value={priceToString(chosenService.price)}
                                    type="number"
                                    name="price"
                                    placeholder="25.00"
                                    handleInput={handleChange}
                                    style={{
                                        margin: '2em 0',
                                        position: 'relative',
                                        zIndex: '1'
                                    }}
                                />

                                {!!newService.deposit &&
                                    <>

                                        <Input
                                            type="number"
                                            name="price-range-start"
                                            message="Starting Price"
                                            value={chosenService.priceRange ? priceToString(chosenService.priceRange[0]) : null}
                                            placeholder="10.00"
                                            handleInput={handleChange}
                                            validationNeeded={false}
                                        />
                                        <Input
                                            type="number"
                                            name="price-range-end"
                                            message="Maximum Price (optional)"
                                            placeholder="200.00"
                                            value={chosenService.priceRange ? priceToString(chosenService.priceRange[1]) : null}
                                            handleInput={handleChange}
                                            validationNeeded={false}
                                        />

                                    </>
                                }
                            </div>
                            {/* </Group> */}

                            {error &&
                                <Message
                                    type="negative"
                                    style={{
                                        margin: '1em 0'
                                    }}
                                    fullWidth={true}
                                >
                                    {error}
                                </Message>
                            }

                            <div
                                style={{
                                    display: 'flex',
                                    gap: '0 1em',
                                }}
                            >

                                <PrimaryBtn
                                    // style={{ margin: '1em 0' }}
                                    fullWidth={true}
                                    onClick={() => updateService(chosenService)}
                                    waiting={awaiting}
                                >
                                    {!!chosenService.title ? 'Update' : 'Add'} Service
                                </PrimaryBtn>
                                {!!chosenService.title && services.filter(service => service.disabled !== true).length > 1 &&
                                    <SecondaryBtn
                                        onClick={() => requiresCheck(`Are you sure you wish to delete this service?`, () => handleRemove(chosenService))}
                                    >
                                        Delete this service
                                        <Cross />
                                    </SecondaryBtn>
                                }

                            </div>
                        </>
                    }
                </Modal>
                <PrimaryBtn
                    style={{
                        margin: '1em 0'
                    }}
                    fullWidth={true}
                    onClick={() => {
                        setNewService({})
                        setServiceModalIsVisible({})
                    }}
                >
                    Add a new service
                    <PlusIcon />
                </PrimaryBtn>
            </Group >
        </>
    )

}

const UL = styled.ul`

    .service-item{
        display: grid;
        grid-template-columns: 1fr 1fr 40px;
        div {
            margin: 0 1em;
        }
    }
`