import React from 'react'
import LogoUpload from './LogoUpload'
import CoverImage from './CoverImage'
import GalleryImages from './GalleryImages'
import Brand from './Brand'

export default function Appearance({ user, shopInfo }) {

    return (
        <>
            <LogoUpload user={user} shopInfo={shopInfo} />
            <CoverImage user={user} shopInfo={shopInfo} />
            <GalleryImages user={user} shopInfo={shopInfo} />
            <Brand user={user} shopInfo={shopInfo} />
        </>
    )
}