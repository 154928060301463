import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import { functions } from '../../HttpFunctions'
import { SubtleListItem, Group, Spinner, Message, Modal, PrimaryBtn, H3 } from 'zyppd-bits'
import { priceToString } from '../../Helpers/priceToString'
import styled from 'styled-components'
import dayjs from 'dayjs'


export default function Transactions({ shopInfo, user }) {
    const currentTimeZone = shopInfo.timezone || 'Europe/London'
    const db = firebase.firestore()

    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [bookings, setBookings] = useState(false)
    const [modal, setModal] = useState(false)



    async function getUpcomingBookings() {
        db.collection(`shops/${user.uid}/bookings`)
            .where("bookingBy", "==", "customer")
            .where("createdAt", ">=", new Date(dayjs().tz(currentTimeZone).subtract(7, 'd').valueOf()))
            // .limit(1)
            .get()
            .then((snapshot) => {
                let data = []
                snapshot.forEach(doc => {
                    data.push(doc.data())
                })
                setBookings(data)
            })
            .catch(err => {
                console.log(err)
                setError("There was a problem loading your transactions, please try again later.")
            })

    }

    async function get_transactions(bookings) {
        setLoading(true)
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                bookings
            })
        };

        try {
            const api = await fetch(`${functions}/payments-payments/get_transactions`, params);
            const response = await api.json()
            setTransactions(response)
            setLoading(false)

            return response

        }
        catch (error) {
            console.log({ error })
            setError(true);
            return error
        }
    }

    useEffect(() => {
        modal && getUpcomingBookings()
    }, [modal])


    useEffect(() => {
        bookings && get_transactions(bookings)
    }, [bookings])



    return (
        <>
            <H3 style={{ marginBottom: '1em' }}>Transactions</H3>
            <Group>
                <PrimaryBtn
                    onClick={() => setModal(true)}
                    fullWidth={true}
                >
                    See recent transactions
                </PrimaryBtn>
            </Group>

            {modal &&
                <Modal
                    isVisible={modal}
                    close={() => setModal(!modal)}
                    shade={true}
                >
                    {error ?
                        <Message fullWidth={true} type="negative">
                            <h4>There was a connection error</h4>
                            <p>Please try again later </p>
                        </Message>

                        : loading ?
                            <div className="loader"
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                <Spinner></Spinner>
                                <strong>
                                    Collecting transactions from the past 7 days, this can take a while...
                                </strong>
                            </div>
                            :
                            <UL>
                                {transactions.length === 0 ?
                                    <Message fullWidth={true}>
                                        No transactions in the last 7 days
                                    </Message>
                                    : transactions
                                        .sort((a, b) => b.created - a.created)
                                        .map(transaction => {
                                            return (
                                                <SubtleListItem key={transaction.id}>
                                                    <div className="created_at">
                                                        <small>
                                                            {dayjs(new Date(transaction.created * 1000)).tz(currentTimeZone).format("LLL")}
                                                        </small>
                                                    </div>
                                                    <figure>

                                                        <div className={`price main ${transaction.refund_id ? 'refunded' : ""} `}>
                                                            <small>Amount {transaction.refund_id ? 'refunded' : "paid"}</small>
                                                            <h4>£{priceToString(transaction.amount_received)}</h4>
                                                        </div>

                                                        <div className={`price`}>
                                                            <small>Application fee</small>
                                                            <h4>£{priceToString(((transaction.application_fee_amount - Math.round((transaction.metadata.service_price / 100 * 1.4) + 20))))}</h4>
                                                        </div>

                                                        <div className={`price`}>
                                                            <small>Processing fee</small>
                                                            <h4>£{priceToString(((Math.round((transaction.metadata.service_price / 100 * 1.4) + 20))))}</h4>
                                                        </div>

                                                        <div className="booking_id">
                                                            <small>Booking Id</small>
                                                            <h4>{transaction.metadata.publicBookingId}</h4>
                                                        </div>
                                                    </figure>
                                                </SubtleListItem>
                                            )
                                        })
                                }
                            </UL>
                    }
                </Modal>
            }
        </>
    )

}


const UL = styled.ul`
    li {
        flex-direction: column;
        align-items: flex-start;
        @media(max-width: 700px){
            font-size: 80%;
        }
    }

    .created_at{
        margin: 0 0 .5em 0; 
    }

    figure {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        div {
            margin-right: .5em;
        }
    }

    .price{
        opacity: .8;
    }
    .main{
        color: ${({ theme }) => theme.brandColor};
    }
        .refunded{
            color: ${({ theme }) => theme.stateColors.negative};
        }
`