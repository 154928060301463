import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import toast from 'react-hot-toast'
import { Group, Checkbox, H3 } from 'zyppd-bits'

export default function NotificationSettings({ user, shopInfo }) {
    const db = firebase.firestore()

    const [notifications, setNotifications] = useState()

    useEffect(() => {
        setNotifications(shopInfo.email_notifications)
    }, [shopInfo])

    const toggleNotifications = () => {

        setNotifications(!notifications)
        toast.promise(
            db.collection(`shops`).doc(user.uid).update({
                email_notifications: !notifications
            }),
            {
                loading: "Updating...",
                success: `Notifications ${!notifications ? 'Enabled' : 'Disabled'}`,
                error: 'Sorry, something went wrong'
            }
        )
    }

    return (
        <>
            <H3 style={{ marginBottom: '1em' }}>Notification Settings</H3>
            <Group>
                <Checkbox
                    checked={notifications}
                    onClick={() => toggleNotifications()}
                    label="Receive email notifications when a booking is created, cancelled or refunded"
                ></Checkbox>

            </Group>
        </>
    )
}


// function Prompt({ children }) {

//     const [checked, setChecked] = useState(false);

//     return (
//         <Modal isVisible={!checked}>

//             <h3 style={{ marginBottom: '.5em' }}>Check me</h3>
//             <div style={{
//                 maxWidth: '200px',
//                 display: 'flex'
//             }}>

//                 <PrimaryBtn type="negative" style={{ marginRight: '1em' }}>
//                     <Cross />
//                 </PrimaryBtn>
//                 <PrimaryBtn>
//                     <Tick />
//                 </PrimaryBtn>

//             </div>
//         </Modal>
//     )

// }