import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { functions } from '../../HttpFunctions'
import { priceToString } from '../../Helpers/priceToString'
import dayjs from 'dayjs'
import { H3, useWindowSize, SecondaryBtn, Message, SubtleListItem, ListItem, AddToCalendar, Modal } from 'zyppd-bits'
import Refund from './Refund'
import RescheduleBooking from './RescheduleBooking'
import { useEffect } from 'react'

import { Call as PhoneIcon, Mail as EmailIcon, Face, Place as LocationIcon, Schedule as ClockIcon, ChevronRight as RightChevron, Whatshot } from '@material-ui/icons'



export default function SelectedBooking({ isVisible, booking, close, shopInfo, uid }) {

    const {
        customer_name,
        customer_email,
        customer_address,
        customer_phone,
        locationAddress,
        time,
        publicBookingId,
        service,
        service_name,
        service_price,
        staffId,
        payment_id,
        refund_id,
        additional_information
    } = booking

    const currentTimeZone = 'Europe/London'
    let s = shopInfo.services.find(s => s.id === service)
    let serviceTitle = service_name || s?.title || 'Service name could not be found'
    const staffName = shopInfo.staff.find(s => s.id === staffId)?.title

    const windowSize = useWindowSize()
    const [refund, setRefund] = useState()
    const [payment, setPayment] = useState()
    const [address, setAddress] = useState(undefined)


    async function retrieve_paymentIntent() {

        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id
            })
        };

        try {
            const api = await fetch(`${functions}/payments-payments/get_payment_info`, params);
            const response = await api.json()
            setPayment(response)
            return response

        }
        catch (error) {
            return error
        }
    }

    async function retrieve_refund() {
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refund_id
            })
        };

        try {
            const api = await fetch(`${functions}/payments-payments/get_refund_info`, params);
            const response = await api.json()
            setRefund(response)

            return response

        }
        catch (error) {
            console.log("error =>", error)
            return error
        }
    }

    const [calendars, setCalendars] = useState()
    async function calender() {

        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                booking: publicBookingId,
                shopId: shopInfo.publicId
            })
        };

        try {

            const api = await fetch(`${functions}/calendar-calendar/calendar`, params);
            const response = await api.json();
            setCalendars(response)

            return response

        }
        catch (error) {
            console.log(error)
            return error
        }

    }


    useEffect(() => {

        refund_id && retrieve_refund()
        payment_id && retrieve_paymentIntent()
        customer_address && setAddress(JSON.parse(customer_address))
        calender()
        return () => {
            // calender(null)
            setPayment(null)
            setRefund(null)
            setAddress(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking])

    const [priceString, setPriceString] = useState()

    useEffect(() => {
        payment && setPriceString(payment.amount_received)
    }, [payment])

    function parseAddressToGoogleMapsURL(line_1, line_2, city, postcode) {

        let string = []
        for (let i in arguments) {
            arguments[i] && string.push(arguments[i].trim().replace(/\s/g, '+'))
        }
        return string.join("%2C")
    }


    const shopLocationAddress = locationAddress && locationAddress !== 'default' && locationAddress !== 'remote' ? JSON.parse(booking.locationAddress) : false;

    return (

        <Modal
            isVisible={isVisible}
            close={close}
            shade={true}
            title="Booking"
        >
            <Message
                fullWidth={true}
                type={booking.paid && !refund ? 'positive' : 'warning'}
                waiting={booking.paid && !refund}
            >
                {booking.amount_paid > 0 && payment && !refund ? (
                    <>
                        {booking.deposit === true && 'Deposit of '}<strong>£{priceToString(priceString)}</strong> Paid
                        <br />
                        {payment.application_fee_amount > 99 ? <small>including £{priceToString(((payment.application_fee_amount - Math.round((booking.service_price / 100 * 1.4) + 20))))} booking fee</small> : ''}
                    </>
                ) : booking.amount_paid > 0 && !payment ? <i>Fetching payment details</i> : null}

                {booking.amount_paid === 0 && `Not Paid £${priceToString(service_price)}`}
                {payment && refund && `£${priceToString(refund.amount)} has been refunded`}
            </Message>
            <div
                style={{ margin: '.5em 0' }}
            >
                <AddToCalendar
                    calendars={calendars}
                    disabled={!calendars}
                />

            </div>
            <ul>
                {
                    shopLocationAddress ?
                        <SubtleListItem>
                            <LocationIcon />
                            <div>
                                <small>Location</small>
                                <h4>{booking.location}</h4>
                                {shopLocationAddress &&
                                    Object.values(shopLocationAddress).map((line, i) => <small>{`${line}${i < Object.values(shopLocationAddress).length - 1 ? ', ' : ' '}`}</small>)
                                }

                            </div>
                        </SubtleListItem>
                        : ''
                }
                <SubtleListItem>
                    <ClockIcon />
                    <div>
                        <small>Time</small>
                        <h4>{dayjs(time).tz(currentTimeZone).format('LLLL')}</h4>
                    </div>

                </SubtleListItem>
                {staffName &&
                    <SubtleListItem>
                        <Face />
                        <div>
                            <small>Staff</small>
                            <h4>{staffName}</h4>
                        </div>
                    </SubtleListItem>
                }
                <SubtleListItem>
                    <Whatshot />
                    <div>
                        <small>Service</small>
                        <h4>{serviceTitle}</h4>
                    </div>
                </SubtleListItem>
                <RescheduleBooking
                    btnStyles={{
                        marginTop: '.5em'
                    }}
                    booking={booking}
                    shopInfo={shopInfo}
                    uid={uid}
                    close={close}
                />
            </ul>


            <CustomerDetails>
                <H3>Customer Details</H3>
                <SubtleListItem>
                    <Face />
                    <div style={{
                        marginRight: '1em'
                    }}>
                        <small>Customer Name</small>
                        <h4>{customer_name}</h4>
                    </div>
                    <div>
                        <small>Booking Id</small>
                        <h4>{publicBookingId}</h4>
                    </div>
                </SubtleListItem>

                {additional_information &&
                    <SubtleListItem>
                        <div>
                            <small>Additional Information</small>
                            <h4>
                                {additional_information}
                            </h4>
                        </div>
                    </SubtleListItem>
                }

                {address &&
                    <SubtleListItem>
                        <LocationIcon />
                        <FlexItem
                            windowSize={windowSize}
                        >
                            <div>
                                <small>Address</small>
                                {address.line_1 && <h4>{address.line_1}</h4>}
                                {address.line_2 && <h4>{address.line_2}</h4>}
                                {address.city && <h4>{address.city}</h4>}
                                {address.postcode && <h4>{address.postcode}</h4>}

                            </div>
                            <a href={`https://www.google.com/maps/dir/?api=1&destination=${parseAddressToGoogleMapsURL(address.line_1, address.line_2, address.city, address.postcode)}`} target="_blank" rel="noopener noreferrer">
                                <SecondaryBtn
                                    style={{
                                        marginTop: `${windowSize.width < 400 ? '.5em' : '0'}`
                                    }}
                                >
                                    Get Directions
                                </SecondaryBtn>
                            </a>
                        </FlexItem>
                    </SubtleListItem>
                }


                <a href={`mailto:${customer_email}?subject=Booking ${publicBookingId} at ${shopInfo.shopName}&body=Hi ${customer_name}, regarding your booking (Booking Id: ${publicBookingId})`}>
                    <ListItem>
                        <EmailIcon />
                        <div>
                            <small>Customer Email</small>
                            <h4>{customer_email}</h4>
                        </div>
                        <RightChevron />
                    </ListItem>
                </a>
                <a href={`tel:${customer_phone}`}>
                    <ListItem>
                        <PhoneIcon />
                        <div>
                            <small>Customer Phone</small>
                            <h4>{customer_phone}</h4>
                        </div>
                        <RightChevron />

                    </ListItem>
                </a>
            </CustomerDetails>


            {!refund && !shopInfo.cancelled &&
                <Refund uid={uid} booking={booking} shopInfo={shopInfo} close={close} />
            }



        </Modal>

    )
}

const CustomerDetails = styled.div`
    margin: .5em 0;
    h3 {
        margin-bottom: .5em;
    }
`
const FlexItem = styled.div`
    display: flex; 
    width: 100%;
    flex-direction: ${({ windowSize }) => windowSize.width < 950 ? 'column' : 'row'};
    justify-content: ${({ windowSize }) => windowSize.width < 950 ? 'flex-start' : 'space-between'};
    align-items: ${({ windowSize }) => windowSize.width < 950 ? 'flex-start' : 'center'};
    a, button{
        margin-top: ${({ windowSize }) => windowSize.width < 950 ? '.5em' : '0'};

    }
`
