import React, { useState, useEffect } from 'react'
import { priceToString } from '../Helpers/priceToString'
import { PrimaryBtn, Group, Modal, Spinner, Message } from 'zyppd-bits'
import { ChevronRightRounded as RightChevron } from '@material-ui/icons';
import RequiresServerConnection from '../Helpers/RequiresServerConnection'
// import RequiresServerConnection from '../../Helpers/RequiresServerConnection'

import { loadStripe } from '@stripe/stripe-js';
import { stripe_pk } from '../HttpFunctions'
import firebase from 'firebase/compat/app'
import dayjs from 'dayjs'
import styled from 'styled-components'

const stripePromise = loadStripe(stripe_pk);

export function SeeUserSubscription({ user, shopInfo, subscription, hideManageBtn }) {

    const db = firebase.firestore();
    const [subscribedProduct, setSubscribedProduct] = useState(undefined)
    const [awaiting, setAwaiting] = useState(false)
    const [error, setError] = useState(false);


    async function getSubscribedProduct(productUrl, priceUrl) {
        const product = await db.doc(productUrl)
            .get()
            .then(doc => doc.data())
            .catch(err => setError(err))

        const price = await db.doc(priceUrl)
            .get()
            .then(doc => doc.data())
            .catch(err => setError("There was a problem getting your subscription, please try again later"))

        return setSubscribedProduct({ product, price })
    }

    useEffect(() => {
        subscription?.product && getSubscribedProduct(subscription.product, subscription.price)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription])




    if (!subscription) return <></>;

    async function goToSubscriptionDashboard() {
        setAwaiting(true)
        const functionRef = firebase
            .app()
            .functions('europe-west2')
            .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');

        const { data } = await functionRef({ returnUrl: window.location.href });

        setAwaiting(false)
        window.location.assign(data.url);
    }

    const product = subscribedProduct && subscribedProduct.product;
    const price = subscribedProduct && subscribedProduct.price;
    const subscriptionDetails = subscription && subscription.subscriptionDetails;
    const cancel_date = subscriptionDetails && !!subscriptionDetails.cancel_at && subscriptionDetails.cancel_at._seconds * 1000;



    return subscription?.product && subscription?.price ? (
        <Group>
            {product &&
                <div>
                    <h2>{product.name}</h2>
                    <p>{product.description}</p>
                    <p><strong>£{priceToString(price.unit_amount)}</strong> Paid each {price.interval}</p>
                    {subscriptionDetails.cancel_at_period_end &&
                        <Message
                            fullWidth={true}
                            type="warning"
                            style={{ marginTop: '.5em' }}
                        >
                            Your subscription is set to automatically end on the {dayjs(cancel_date).format('Do')} of {dayjs(cancel_date).format('MMMM')} {dayjs(cancel_date).format('YYYY')}
                        </Message>
                    }
                </div>
            }
            <PrimaryBtn
                onClick={() => goToSubscriptionDashboard()}
                fullWidth={true}
                style={{
                    marginTop: '.5em',
                    marginLeft: 'auto'
                }}
                waiting={awaiting}
            >
                Manage Subscription
                <RightChevron />
            </PrimaryBtn>
        </Group>
    ) : <></>

}

export function SubscriptionOptions({ user }) {


    const [products, setProducts] = useState([])
    const db = firebase.firestore()
    const [awaiting, setAwaiting] = useState()

    function getProducts() {

        db.collection('products')
            .where('active', '==', true)
            .get()
            .then(async function (querySnapshot) {

                querySnapshot.forEach(async function (prod, i) {

                    const priceSnap = await prod.ref.collection('prices').get();
                    let prices = []
                    priceSnap.docs.forEach((doc) => {
                        prices.push({ id: doc.id, ...doc.data() })
                    });

                    setProducts(prevState => {
                        return prevState.id !== prod.id && [...prevState, {
                            id: prod.id,
                            product: prod.data(),
                            prices
                        }]
                    })
                });

            })
            .catch(err => console.log(err))

    }

    useEffect(() => {
        getProducts()
    }, [])

    async function test_func(subscription) {

        setAwaiting(true)
        const stripe = await stripePromise;

        const docRef = await db
            .collection('customers')
            .doc(user.uid)
            .collection('checkout_sessions')
            .add({
                price: subscription,
                allow_promotion_codes: true,
                success_url: `${window.location.origin}/awaiting_subscription`,
                cancel_url: window.location.origin,
            });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            const { error, sessionId } = snap.data();
            // setAwaiting(false)
            if (error) {
                // Show an error to your customer and 
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (sessionId) {

                stripe.redirectToCheckout({ sessionId });
            }
        });

    }

    return products && (
        <>
            {products.map((item, i) => {
                const productName = item.product.name;
                return (
                    <SubscriptionContainer key={item.id}>
                        <h1>
                            {productName}
                        </h1>
                        <p
                            style={{
                                maxWidth: '45ch',
                                margin: '0 0 1em 0'
                            }}
                        >
                            {item.product.description}
                        </p>
                        <PricesContainer>
                            {item.prices.sort((a, b) => a.unit_amount - b.unit_amount).map(price => {
                                return (
                                    <PrimaryBtn
                                        onClick={() => {
                                            test_func(price.id)
                                        }}
                                    >
                                        £{priceToString(price.unit_amount)} per {price.interval}
                                        <RightChevron />
                                    </PrimaryBtn>
                                )
                            }
                            )}

                        </PricesContainer>
                    </SubscriptionContainer>
                )
            })}
            <Modal
                isVisible={awaiting}
                shade={true}
            >
                <Spinner />
            </Modal>
        </>
    )
}

// export default RequiresServerConnection(SubscriptionOptions);
// export RequiresServerConnection(SubscriptionOptions);

const SubscriptionContainer = styled.div`
    max-width: 600px;
    margin: 0 auto;
`
const PricesContainer = styled.div`
    display: flex; 
    > button {
        flex: 1 1 auto;
        &:not(:last-of-type){
            margin-right: .5em;
        }
    }
`