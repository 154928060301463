/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/compat/app'
import { Spinner } from 'zyppd-bits'
import SearchForBooking from './SearchForBooking'
import SelectLocation from './SelectLocation'
import SelectStaff from './SelectStaff'
import dayjs from 'dayjs'

import ListBookings from './ListBookings'
const Container = styled.div``

const Filters = styled.div`
    display: flex;
    align-items: center; 
    .filter-item {
        flex: 1 1 auto;
    }
    div:not(:last-of-type){
        margin-right: .5em;
    }
`

export default function UpcomingBookings({ uid, shopInfo, setNumOfUpcomingBookings }) {
    const db = firebase.firestore()
    const currentTimeZone = shopInfo.timezone || 'Europe/London'
    const [bookings, setBookings] = useState([])
    const [fetchingBookings, setFetchingBookings] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState({})
    const [selectedStaff, setSelectedStaff] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState(false);

    const [locationAndStaff, setLocationAndStaff] = useState({ location: false, staff: false });


    const ONE_HOUR = 3600000
    const currTime = dayjs().tz(currentTimeZone).valueOf() - ONE_HOUR


    //TODO: add dialogue: 'You have two bookings today"
    // const ONE_HOUR_IN_MILLISECONDS = 3600000

    function getUpcomingBookings() {

        setFetchingBookings(true);

        const query = db.collection(`shops/${uid}/bookings`).where('time', '>', currTime)

        const locationChosen = selectedLocation === 'false' ? false : !!selectedLocation;
        const staffChosen = selectedStaff === 'false' || selectedStaff === 'all_staff' ? false : !!selectedStaff;

        const locationQuery = query.where('locationId', '==', selectedLocation);
        const staffQuery = query.where('staffId', '==', selectedStaff);
        const locationAndStaffQuery = query.where('staffId', '==', selectedStaff).where('locationId', '==', selectedLocation);


        const process = (querySnapshot) => {
            let data = []

            querySnapshot.forEach((doc) => {
                if (!doc.data().cancelled && !doc.data().refunded)
                    data.push({ ...doc.data(), docId: doc.id })
            })

            setNumOfUpcomingBookings(data.length)
            setFetchingBookings(false);
            setBookings(data)
        }


        if (staffChosen && locationChosen) {

            locationAndStaffQuery
                .orderBy('time')
                .onSnapshot(querySnapshot => process(querySnapshot))


        } else if (staffChosen) {
            staffQuery
                .orderBy('time')
                .onSnapshot(querySnapshot => process(querySnapshot))

        } else if (locationChosen) {

            locationQuery
                .orderBy('time')
                .onSnapshot(querySnapshot => process(querySnapshot))


        } else {

            query
                .orderBy('time')
                .onSnapshot(querySnapshot => process(querySnapshot))
        }

    }

    //each time the location is changed and current staff doesn't work at current location, set staff to 'all staff'
    useEffect(() => {

        if (selectedStaff === false || selectedLocation === false || selectedLocation === "false") return;
        let currStaffAtCurrLocation = shopInfo.staff.find(sta => sta.location === selectedLocation && sta.id === selectedStaff)
        if (!currStaffAtCurrLocation) setSelectedStaff(false);

    }, [selectedLocation]);


    useEffect(() => { setLocationAndStaff(prevState => { return { ...prevState, 'location': selectedLocation, 'staff': selectedStaff } }); }, [selectedLocation, selectedStaff])

    useEffect(() => { getUpcomingBookings() }, [locationAndStaff])


    return (
        <Container>
            {!fetchingBookings ?
                <>


                    <Filters>
                        {shopInfo?.locations?.length > 0 && shopInfo.locations.filter(location => location.disabled !== true).length > 1 ?
                            <SelectLocation
                                locations={shopInfo.locations}
                                setSelectedLocation={setSelectedLocation}
                            />
                            : null
                        }
                        {
                            shopInfo.staff.filter(member => member.disabled !== true).length > 1
                                ?
                                <SelectStaff
                                    staff={shopInfo.staff}
                                    selectedStaff={selectedStaff}
                                    selectedLocation={selectedLocation}
                                    setSelectedStaff={setSelectedStaff}
                                />

                                : null
                        }
                    </Filters>

                    <SearchForBooking
                        uid={uid}
                        handleClick={setSelectedBooking}
                    />

                    <ListBookings bookings={bookings}></ListBookings>

                </>

                : <Spinner />
            }

        </Container >
    )
}
