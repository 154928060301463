import React, { useState } from 'react'
import {
    Group,
    PrimaryBtn,
    Message,
    Modal,
    SubtleListItem,
    Input,
    TextArea,
    Checkbox,
    SecondaryBtn,
    useCheck,
    H2,
} from 'zyppd-bits'
import { CloseRounded as Cross, EditRounded as EditIcon, AddRounded as PlusIcon } from '@material-ui/icons'
import { useEffect } from 'react'
import firebase from 'firebase/compat/app'
import createUID from '../../../../Helpers/CreateUID'
import toast from 'react-hot-toast';


export default function Staff({ user, shopInfo }) {

    const db = firebase.firestore()
    const { requiresCheck } = useCheck()
    const [error, setError] = useState(false)

    let { staff, services } = shopInfo
    staff = staff.filter(member => member.disabled !== true)
    const [staffModal, setStaffModal] = useState(false)
    const [newStaff, setNewStaff] = useState(false)

    const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


    useEffect(() => {
        staffModal && setNewStaff(staffModal)
    }, [staffModal])

    useEffect(() => {
        newStaff && setStaffModal(newStaff)
    }, [newStaff])

    function handleChange(e) {
        e = e.target || e
        setError(false)
        if (e.name === 'daysOff') {
            if (newStaff.daysOff && newStaff.daysOff.includes(e.value)) {
                e.value = [...newStaff.daysOff.filter(day => day !== e.value)]
            } else {
                e.value = newStaff.daysOff ? [...newStaff.daysOff, e.value] : [e.value]
            }
        }

        if (e.name === 'services') {
            if (e.value === 'all services') {
                e.value = newStaff?.services?.length === services?.length ? false : services.map(service => service.id)
            } else {
                if (newStaff.services && newStaff.services.includes(e.value)) {
                    e.value = [...newStaff.services.filter(service => service !== e.value)]
                } else {
                    e.value = newStaff.services ? [...newStaff.services, e.value] : [e.value]
                }
            }
        }

        setNewStaff(prevState => {
            return {
                ...prevState,
                [e.name]: e.value
            }
        })
    }

    function handleUpdate() {
        if (!newStaff.title) return setError("A staff name is required")
        if (newStaff.daysOff && newStaff.daysOff.length === 7) return setError("At least one working day is required")
        if (!newStaff.services || newStaff.services.length === 0) return setError("At least one service is required")

        const newStaffArr = newStaff.id ? staff.map(member => {
            return member.id === newStaff.id ? {
                ...member,
                ...newStaff,
                daysOff: newStaff.daysOff || [] // an empty days off array (if no days off are chosen, is required)
            } : {
                ...member
            }
        }) : [...staff, { ...newStaff, daysOff: newStaff.daysOff || [], id: createUID(10) }]


        toast.promise(
            db.collection('shops').doc(user.uid)
                .update({ staff: newStaffArr }),
            {
                loading: 'Updating staff...',
                success: <b>Staff updated</b>,
                error: <b>Sorry, looks like something went wrong</b>
            }

        )
        setStaffModal(false)


    }

    function handleRemove(chosenStaff) {

        const withDeletedStaff = staff.map(member => {
            return member.id === chosenStaff.id ?
                {
                    ...member,
                    disabled: true
                } : { ...member }
        })

        toast.promise(
            db.collection('shops').doc(user.uid)
                .update({ staff: withDeletedStaff }),
            {
                loading: 'Removing staff...',
                success: 'Staff removed',
                error: 'Sorry, looks like something went wrong'
            }

        )
        setStaffModal(false)
        // db.collection('shops').doc(user.uid)
        //     .update({ staff: withDeletedStaff })
        //     .then(() => {
        //         addToast(`Staff has been removed`, {
        //             appearance: 'warning',
        //             autoDismiss: true,
        //         })
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
    }

    return (
        <>
            <H2>Staff</H2>
            <Group>
                {
                    staff.length > 0 &&
                    staff
                        .map(member => {
                            return (
                                <SubtleListItem
                                    key={member.id}
                                    onClick={() => setStaffModal(member)}
                                >
                                    <div>
                                        <small>Name</small>
                                        <h4>
                                            {member.title}
                                        </h4>
                                    </div>
                                    <EditIcon />
                                </SubtleListItem>
                            )
                        })
                }


                <PrimaryBtn
                    onClick={() => setStaffModal(true)}
                    fullWidth={true}
                    style={{
                        margin: '1em 0'
                    }}
                >
                    Add new staff member <PlusIcon />
                </PrimaryBtn>
            </Group>


            <Modal
                isVisible={!!staffModal}
                close={() => setStaffModal(false)}
                shade={true}
            >
                <Input
                    type="text"
                    value={staffModal.title}
                    name="title"
                    message={'Staff Name'}
                    placeholder="Staff Name"
                    validationNeeded={false}
                    handleInput={handleChange}
                />
                <TextArea
                    type="text"
                    value={staffModal.description}
                    name="description"
                    message={'Staff Description (optional)'}
                    placeholder="Staff Description (optional)"
                    validationNeeded={false}
                    handleInput={handleChange}
                />

                <div
                    style={{
                        marginTop: '1em'
                    }}
                >
                    <div
                        style={{
                            padding: '.5em 0'
                        }}
                    >
                        <h4>What days do they work?</h4>
                    </div>
                    {daysOfTheWeek.map((day, i) => {
                        let j = i === 6 ? 0 : i + 1
                        return (
                            <Checkbox
                                key={day}
                                label={day}
                                onClick={(e) => handleChange({ name: 'daysOff', value: j })}
                                checked={staffModal.daysOff ? !staffModal.daysOff.includes(j) : true}
                                name='deposit'
                            />)
                    })}
                </div>

                {services.length > 0 ?
                    <div
                        style={{
                            margin: '1em 0 2em 0'
                        }}
                    >
                        <div
                            style={{
                                padding: '.5em 0'
                            }}
                        >
                            <h4>Services</h4>
                        </div>
                        <div
                            style={{
                                padding: '.5em 0'
                            }}
                        >
                            <Checkbox
                                label={"All services"}
                                onClick={(e) => handleChange({ name: 'services', value: 'all services' })}
                                checked={newStaff.services ? services.map(s => s.id).sort().join(',') === newStaff.services.sort().join(',') : false}
                                name='deposit'
                            />
                        </div>


                        {services.filter(s => s.disabled !== true)
                            .map((service, i) => {
                                return (
                                    <Checkbox
                                        label={service.title}
                                        onClick={(e) => handleChange({ name: 'services', value: service.id })}
                                        checked={staffModal.services ? staffModal.services.includes(service.id) : false}
                                        name='services'
                                    />)
                            })}
                    </div> : null
                }

                {error &&
                    <Message
                        type="negative"
                        fullWidth={true}
                    >
                        {error}
                    </Message>
                }

                <div
                    style={{
                        display: 'flex',
                        gap: '0 1em',
                        margin: '1em 0'
                    }}
                >
                    <PrimaryBtn
                        onClick={() => handleUpdate()}
                        fullWidth={true}
                    // type="positive"
                    >
                        Update Staff
                    </PrimaryBtn>
                    {staffModal.id &&
                        <SecondaryBtn
                            onClick={async () => {
                                const message = newStaff.id ?
                                    await db.collection(`shops/${user.uid}/bookings`)
                                        .where('time', '>', new Date().getTime())
                                        .where('staffId', '==', newStaff.id)
                                        .get()
                                        .then((doc) => `They have ${doc.size} upcoming bookings`) : ''

                                requiresCheck(
                                    `Are you sure you wish to remove this staff? ${message}`,

                                    () => handleRemove(newStaff))

                            }}
                        >
                            Remove this staff
                            <Cross />
                        </SecondaryBtn>
                    }
                </div>

            </Modal>

        </>
    )
}