import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
// import { format } from 'date-fns'
import { PrimaryBtn, Input, Checkbox, Modal, Select, SecondaryBtn, H3 } from 'zyppd-bits'
import CreateUID from '../../Helpers/CreateUID'
import getDays from '../../Helpers/getDays'
import getTimes from '../../Helpers/getTimes'
import dayjs from 'dayjs'
import firebase from 'firebase/compat/app'
import { Add, Face as FaceIcon, Mail as EmailIcon, Phone as PhoneIcon, ChevronRight as RightChevron, ChevronLeft as LeftChevron } from '@material-ui/icons'
import toast from 'react-hot-toast'

export default function AddBookings({ user, shopInfo }) {

    const [createBookingModal, setCreateBookingModal] = useState(false)
    return (
        <>
            <CreateBooking isVisible={createBookingModal} close={() => setCreateBookingModal(false)} user={user} shopInfo={shopInfo} />

            <PrimaryBtn
                style={{
                    position: 'fixed',
                    bottom: '2em',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    margin: '0 auto',
                    padding: '.5em 3em',
                    zIndex: '3'
                }}
                onClick={() => {
                    setCreateBookingModal(!createBookingModal)
                }}
            >
                <Add />
            </PrimaryBtn>

        </>
    )
}



function BookingDetails({ shopInfo, user, selection, handleChange }) {

    const { services, staff, numOfBookingsDays, days, locations = false, remote = false } = shopInfo

    const [selectedDay, setSelectedDay] = useState({})
    const [parseTimes, setParseTimes] = useState()
    const [parseDates, setParseDates] = useState();

    async function fetchTimes(selectedDay) {
        const t = await getTimes(selection.date, shopInfo, { bookings: true, locationId: selection.locationId })
        setParseTimes(t)
    }

    async function fetchDays() {
        const d = await getDays({
            numOfDays: numOfBookingsDays,
            shopInfo: shopInfo,
            staffDaysOff: selection.daysOff,
            staffName: staff.find(st => st.id === selection.staffId)?.title || '000',
            locationId: selection.locationId
        });

        setParseDates(d)
    }

    useEffect(() => {
        if (selection.date !== '') {
            setSelectedDay(days[dayjs(selection.date).day()])
            fetchTimes(days[dayjs(selection.date).day()])
        }

        fetchDays()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection])

    const parseServices = useMemo(() => services.map((service, i) => {
        return {
            value: service.id,
            label: service.title,
            name: 'service',
            amountOfTime: service.amountOfTime,
            selected: true,
            service_price: service.price,
            service_name: service.title
        }

    }), [services])

    const parseStaff = useMemo(() => staff.filter(member => member.disabled !== true).map(member => {
        return {
            value: member.id,
            label: member.title,
            name: 'staffId',
            daysOff: member.daysOff
        }
    }), [staff])

    const parseLocations = useMemo(() => locations && locations.filter(loc => loc.disabled !== true).map(loc => {
        return {
            value: loc.id,
            label: loc.title,
            name: 'location',
            locationAddress: JSON.stringify(loc.address),
            location: loc.title,
            locationId: loc.id
        }
    }), [locations])


    return (
        <InputContainer>

            {parseLocations?.length > 1 && !remote &&
                <figure>
                    <H3>Location</H3>
                    <Select
                        isSearchable={false}
                        options={parseLocations}
                        onChange={handleChange}
                    />
                </figure>

            }

            <figure>
                <H3>Service</H3>
                <Select
                    isSearchable={false}
                    options={parseServices}
                    onChange={handleChange}
                />
            </figure>

            {parseStaff && parseStaff.length > 0 &&
                <figure>
                    <H3>Staff</H3>
                    <Select
                        isDisabled={selection.service === '' ? true : false}
                        isSearchable={false}
                        options={parseStaff}
                        onChange={handleChange}
                    />
                </figure>
            }

            <figure>
                <H3>Date</H3>
                <Select
                    isDisabled={(parseStaff.length > 0 && selection.staffId === '' ? true : false) || (parseLocations.length > 0 && selection.locationId === '' ? true : false)}
                    isSearchable={false}
                    options={parseDates}
                    onChange={handleChange}
                />
            </figure>

            <figure>
                <H3>Time</H3>
                <p style={{ marginBottom: '.5em' }}>Choose a date first</p>
                <Select
                    isDisabled={Object.entries(selectedDay).length > 0 ? false : true}
                    options={parseTimes}
                    onChange={handleChange}
                    isSearchable={false}
                />
            </figure>
        </InputContainer>
    )
}

const InputContainer = styled.div`
    figure{
        margin: 1em 0;
        h3{
            margin: .25em 0;
        }
        .zyppd__menu{
            z-index: 100;
            position: relative;
        }
    }
`

function CustomerAddress({ selection, handleChange }) {

    return (
        <>
            <h4>Customer Address</h4>
            <Input
                type="line_1"
                name={"line_1"}
                placeholder={"Address Line 1"}
                message="Address Line 1"
                handleInput={handleChange}
            >
            </Input>
            <Input
                type="line_2"
                name={"line_2"}
                placeholder={"Address Line 2"}
                message="Address Line 2"
                handleInput={handleChange}
            >
            </Input>
            <Input
                type="city"
                name={"city"}
                placeholder={"City"}
                message="City"
                handleInput={handleChange}
            >
            </Input>
        </>
    )
}
function CustomerDetails({ selection, handleChange, sendConfirmationEmail, setSendConfirmationEmail, remoteShop }) {

    return (
        <>

            {remoteShop &&
                <CustomerAddress
                    handleChange={handleChange}
                />
            }

            <>

                <Input type="customer_name" name={"customer_name"} placeholder={"Customer Name"} message="Customer Name" handleInput={handleChange}>
                    <FaceIcon />
                </Input>

                <Input type="customer_email" name={"customer_email"} placeholder={"Customer Email"} message="Customer Email" handleInput={handleChange}>
                    <EmailIcon></EmailIcon>
                </Input>

                <Input type="customer_phone" name={"customer_phone"} placeholder={"Customer Phone"} message="Customer Phone" handleInput={handleChange}>
                    <PhoneIcon />
                </Input>

                <div style={{
                    marginTop: '1.5em'
                }}>
                    <Checkbox
                        checked={sendConfirmationEmail}
                        onClick={setSendConfirmationEmail}
                        label="Send confirmation email to customer"
                    ></Checkbox>
                </div>

            </>
        </>
    )
}


function CreateBooking({ isVisible, user, shopInfo, close }) {

    const db = firebase.firestore()
    const [sendConfirmationEmail, setSendConfirmationEmail] = useState(true)


    const [notificationModal, setNotificationModal] = useState({
        isVisible: false
    })

    const [state, setState] = useState(false) // false = booking details, true = customer details
    const [awaiting, setAwaiting] = useState(false) // false = booking details, true = customer details
    const [selection, setSelection] = useState({
        location: false,
        locationId: false,
        locationAddress: 'default',
        service: '',
        staffId: '',
        date: '',
        time: '',
        customer_name: '',
        customer_email: '',
        customer_phone: '',
        sendConfirmationEmail: sendConfirmationEmail
    })



    useEffect(() => {
        handleChange({
            name: 'sendConfirmationEmail',
            value: sendConfirmationEmail
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendConfirmationEmail])


    const [customer_address, setCustomerAddress] = useState(false)
    function handleChange(e) {

        if (!e) return
        e = e.target || e

        if (
            e.name === 'line_1' ||
            e.name === 'line_2' ||
            e.name === 'city' ||
            e.name === 'postcode'
        ) {
            setCustomerAddress(prevState => {
                return {
                    ...prevState,
                    [e.name]: e.value
                }
            })
        }

        setSelection(prevState => {

            return {
                ...selection,
                customer_address,
                // ...locationInfo,
                [e.name]:
                    e.name === 'line_1' ||
                        e.name === 'line_2' ||
                        e.name === 'city' ||
                        e.name === 'postcode' ? undefined : e.value,
                // displayVals: {
                //     ...prevState.displayVals,
                //     [e.name]: e.label,
                // },
                ...e,
            }
        })

    }



    function createBooking() {
        const currentTimeZone = 'Europe/London'
        const { sendConfirmationEmail = true, customer_email, customer_name, customer_phone, date, time, service, service_name, service_price, staffId, customer_address, location, locationId, locationAddress } = selection

        const { url: shopUrl, shopName, publicId, remote } = shopInfo

        const bookingId = CreateUID(25)
        const publicBookingId = CreateUID(5)


        const day = dayjs(time).tz(currentTimeZone)
        const data = {
            sendConfirmationEmail: sendConfirmationEmail,
            time,
            date, service, staffId,
            dateString: `${day.format('dddd')} ${day.format('Do')} ${day.format('MMMM')}`,
            timeString: day.tz(currentTimeZone).format('LT'), // remove ' – N bookings'
            customer_email, customer_name, customer_phone,
            customer_address: customer_address && JSON.stringify(customer_address),
            remote: remote ? remote : false,
            shopUrl, shopName, publicId,
            publicBookingId,
            refunded: false,
            paid: false,
            shopId: publicId,
            shop_public_id: publicId,
            service_name,
            service_price,
            amount_paid: 0,
            bookingId,
            bookingBy: 'shop',
            createdAt: new Date(),
            location, locationId, locationAddress,
            // ...selection
        }

        const query = db.collection(`shops/${user.uid}/bookings`).doc(bookingId)

        // toast.promise(
        //     query.set({ ...data }),
        //     {
        //         loading: 'Creating booking...',
        //         success: <b>Booking created</b>,
        //         error: <b>Sorry, there seems to have been a problem</b>
        //     }
        // )

        // setSelection({
        //     service: '',
        //     staffId: '',
        //     date: '',
        //     time: '',
        //     customer_name: '',
        //     customer_email: '',
        //     customer_phone: '',
        //     displayVals: {},
        //     sendConfirmationEmail: sendConfirmationEmail
        // })
        // setState(false)

        // close();


        setAwaiting(true)
        query.set({ ...data })
            .then(() => {

                setSelection({
                    service: '',
                    staffId: '',
                    date: '',
                    time: '',
                    customer_name: '',
                    customer_email: '',
                    customer_phone: '',
                    location: false,
                    locationId: false,
                    locationAddress: 'default',
                    displayVals: {},
                    sendConfirmationEmail: true
                })
                toast.success(<b>Booking Created</b>)
                setState(false)
                setAwaiting(false)
                close()


            })
            .catch(err => {
                setNotificationModal({
                    isVisible: true,
                    title: 'There was an error creating this booking',
                    // message: payment.error.message,
                    type: 'negative',
                    btn_content: 'Done',
                    cb: () => {
                        setAwaiting(false)
                        setSelection({})
                        setNotificationModal({ isVisible: false })
                    }
                })
                console.log(err)
            })

    }

    function handleClose() {
        close()
        setState(false)
        setSelection({
            service: '',
            staffId: '',
            date: '',
            time: '',
            customer_name: '',
            customer_email: '',
            customer_phone: '',
            // displayVals: {},
            sendConfirmationEmail: sendConfirmationEmail
        })
    }
    return shopInfo && (
        <>
            {/* <Route exact path="/createbooking"> */}
            <Modal
                isVisible={isVisible && !notificationModal.isVisible}
                close={() => handleClose()}
                shade={true}
                title="Create booking"
                style={{
                    padding: '4rem 1em 4rem 1em',

                    zIndex: '20',
                    maxHeight: '100vh',
                    overflowY: 'scroll'
                }}
            >

                {!state ? // TODO: Switch this boolean around
                    <BookingDetails
                        selection={selection}
                        user={user}
                        shopInfo={shopInfo}
                        handleChange={handleChange}
                    />
                    :
                    <CustomerDetails
                        selection={selection}
                        handleChange={handleChange}
                        sendConfirmationEmail={sendConfirmationEmail}
                        setSendConfirmationEmail={() => { setSendConfirmationEmail(!sendConfirmationEmail) }}
                        remoteShop={shopInfo.remote}
                    />


                }

                <div
                    style={{
                        display: 'flex',
                        padding: '1em 0'
                    }}
                >
                    {state &&
                        <SecondaryBtn
                            style={{
                                marginRight: '1em'
                            }}
                            onClick={() => setState(!state)}
                        >
                            <LeftChevron />
                        </SecondaryBtn>
                    }

                    <PrimaryBtn
                        fullWidth={true}
                        disabled={awaiting || selection.service === '' || selection.date === '' || selection.time === ''}
                        waiting={awaiting}
                        handleChange={handleChange}
                        onClick={() => !state ? setState(!state) : createBooking()}
                    >
                        {!state ?
                            < RightChevron />
                            : 'Create Booking'
                        }
                    </PrimaryBtn>

                </div>
            </Modal>

            {
                notificationModal.isVisible &&
                <Modal
                    shade={true}
                    isVisible={notificationModal.isVisible}
                >
                    <H3>{notificationModal.title}</H3>
                    <p style={{
                        maxWidth: '25ch'
                    }}>{notificationModal.message}</p>

                    <PrimaryBtn
                        type={notificationModal.type}
                        fullWidth={true}
                        onClick={() => notificationModal.cb ? notificationModal.cb() : null}
                        style={{
                            marginTop: '2em'
                        }}
                    >
                        {notificationModal.type === 'negative' || notificationModal.type === 'warning' &&
                            <LeftChevron type={notificationModal.type} />
                        }
                        {notificationModal.btn_content}
                    </PrimaryBtn>
                </Modal>
            }
        </>
    )
}


const Header = styled.header`
    display: flex;
    align-items: center;
`