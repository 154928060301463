// import { render } from '@testing-library/react'
import React, { useContext } from 'react'
import AuthContext from '../Auth/AuthContext'
import { Message, Group } from 'zyppd-bits'
import LoadingScreen from '../Components/LoadingScreen'
import { functions } from '../HttpFunctions'
export default function RequiresServerConnection(Component) {

    return class ServerConnection extends React.Component {
        state = {
            hasServer: 'loading',
            shopId: this.props.shopInfo.publicId
        }


        async componentDidMount() {

            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    body: JSON.stringify({
                        shopId: this.state.shopId
                    })
                }
            };


            try {
                const api = await fetch(`${functions}/app/check_account_is_valid`, params);
                const response = await api.json()

                this.setState({ hasServer: true });
            }
            catch (error) {
                if (error.name === 'TypeError') {
                    console.log('Thereeeeeeee was a network error.');
                    this.setState({ hasServer: false });
                }
                return error
            }
        }
        render() {

            if (this.state.hasServer === 'loading') {
                return (
                    <LoadingScreen>Loading...</LoadingScreen>
                )
            }

            if (this.state.hasServer) {
                return (
                    <>
                        <Component {...this.props} />
                    </>
                );

            } else {
                return (
                    <div style={{ height: '100%' }}>
                        <h4>{this.props.title}</h4>
                        <Group>
                            <Message fullWidth type="negative">Sorry, it looks like there was an error connecting to our servers</Message>
                        </Group>
                    </div>
                )
            }

        }
    }
}

(async function () {
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };


    try {
        const api = await fetch(`${functions}/app/check_account_is_valid`, params);
        const response = await api.json()
        console.log(response)
        // setAccountValidated(response)
    }
    catch (error) {
        if (error.name === 'TypeError') {
            console.log('Thereeeeeeee was a network error.');
        }
        return error
    }
})();
// export default RequiresServerConnection