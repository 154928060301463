import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'zyppd-bits'

const Container = styled.div`
    position: fixed; 
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.background}
`

export default function LoadingScreen({ children }) {

    return (
        <Container>
            <Spinner />
            {children}
        </Container>
    )
}