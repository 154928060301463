

import dayjs from 'dayjs'
import firebase from 'firebase/compat/app'
export default async function times(chosenDay, shopInfo, { staffId = false, bookings = false, locationId = false }) {

    const db = firebase.firestore()
    // set bookings to true to get bookings from firestore to add number of bookings at each time to label, add staffId to show bookings only for that specific staff
    const currentTimeZone = 'Europe/London'
    if (!shopInfo) return


    const bk = await db.collection(`shops/${shopInfo.uid}/bookings`)
        .where('date', '==', chosenDay)
        .get()
        .then((snapshot) => {
            let data = []
            snapshot.forEach(snap => {
                !snap.data().cancelled && data.push(snap.data())
            })
            return data
        })


    const d = dayjs(new Date(chosenDay).setHours(0, 0, 0, 0));

    const isAtChosenLocation = locationId && (locationId !== 'default' && locationId !== 'remote') && shopInfo.locations?.length > 0;
    const daysFromShopOrLocation = isAtChosenLocation ? shopInfo.locations.find(loc => loc.id === locationId).days : shopInfo.days

    const dayInfo = daysFromShopOrLocation.find(day => day.day === d.day());
    const openingTime = dayInfo.firstBookingTime.split(":");
    const closingTime = dayInfo.lastBookingTime.split(":");


    const startTime = d.hour(openingTime[0]).minute(openingTime[1]).valueOf()
    const endTime = d.hour(closingTime[0]).minute(closingTime[1]).valueOf()


    const TIME_SLOT_LENGTH = shopInfo.timeSlotLength || 30


    let timesArr = []
    let timeVal = 0
    let diff = endTime - startTime

    while (timeVal <= diff) {
        const timeSlot = dayjs(startTime).add(timeVal, 'milliseconds')
        const numBookingsAtThisTime = bk.filter(booking => booking.time === timeSlot.valueOf()).length

        timesArr.push({
            value: timeSlot.valueOf(),
            label: `${timeSlot.format('LT')}${numBookingsAtThisTime > 0 ? ` – ${numBookingsAtThisTime} booking${numBookingsAtThisTime > 1 ? 's' : ''}` : ''}`,
            name: 'time'
        })
        timeVal += TIME_SLOT_LENGTH * 60000 // convert to millis
    }


    return timesArr
}

