/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, PrimaryBtn, Pill, Select, SecondaryBtn, useCheck } from 'zyppd-bits'
import times from '../../Helpers/getTimes'
import getDays from '../../Helpers/getDays'
import dayjs from 'dayjs'
import firebase from 'firebase/compat/app'
import toast from 'react-hot-toast'
function RescheduleBooking({ booking, shopInfo, uid, close, btnStyles = {} }) {

    const db = firebase.firestore();
    const { requiresCheck } = useCheck();

    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [awaiting, setAwaiting] = useState(false);

    const { staff, numOfBookingsDays, services } = shopInfo;

    const [chosenStaff] = useState(() => booking.staffId && booking.staffId !== '000' ? staff.find(member => member.id === booking.staffId) : '000', [staff]);
    const [parseServices, setParsedServices] = useState([]);
    const [parseStaff, setParsedStaff] = useState([]);
    const [currentStaff, setCurrentStaff] = useState();
    const [currentService, setCurrentService] = useState();
    const [parseDates, setParsedDates] = useState([]);
    const [parseTimes, setParseTimes] = useState([]);
    const [currentDate, setCurrentDate] = useState();
    const [currentTime, setCurrentTime] = useState();

    const [selection, setSelection] = useState({
        ...booking,
    });

    useEffect(() => {

        const currServ = services.filter(s => s.disabled !== true).findIndex(s => s.id === booking.service)

        setCurrentService(currServ)

        setParsedServices(services.filter(s => s.disabled !== true).map(s => {
            return {
                value: s.id,
                label: s.title,
                name: 'service'
            }
        }))

        setCurrentStaff(staff.filter(s => s.disabled !== true).findIndex(s => s.id === booking.staffId))

        setParsedStaff(shopInfo.staff.filter(s => s.disabled !== true).map(staff => {
            return {
                value: staff.id,
                label: staff.title,
                name: 'staffId'
            }
        }));

    }, [shopInfo])

    async function fetchDates() {
        const d = await getDays({
            numOfDays: numOfBookingsDays,
            shopInfo: shopInfo,
            staffDaysOff: selection.daysOff,
            staffName: selection.staffId || '000',
            // [numOfBookingsDays], 
            // locationId: f
        })
        chosenStaff && setParsedDates(d);
    }
    useEffect(() => {
        fetchDates();
    }, [shopInfo, booking])


    useEffect(() => {
        setCurrentDate(parseDates.findIndex((date) => date.value === dayjs(booking.time).startOf('d').valueOf()))
    }, [parseDates])

    useEffect(() => {
        parseTimes ? setCurrentTime(parseTimes[parseTimes.findIndex((time) => time.value === dayjs(selection.time).valueOf())]) : setCurrentTime(false)
    }, [parseTimes, selection.time])

    useEffect(() => {
        parseDates[currentDate] && console.log(dayjs(parseDates[currentDate].value).format('L'))
        parseDates[currentDate] && setSelection(prevState => {
            return {
                ...prevState,
                date: dayjs(parseDates[currentDate].value).format('L')
            }
        })

    }, [currentDate])



    async function getTimes() {
        let t = await times(selection.date, shopInfo, { bookings: true, locationId: booking.locationId })
        return setParseTimes(t)
    }

    useEffect(() => {
        if (selection.date !== '') {
            getTimes()
        }
        if (selection.date !== booking.date) {
            setSelection(prevState => {
                return {
                    ...prevState,
                    time: null
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection.date])


    function handleChange(e) {
        console.log(dayjs(e.value).format('llll'))
        setSelection(prevState => {
            return {
                ...prevState,
                [e.name]: e.value,
            }
        })

    }

    function handleReschedule() {

        selection.handleClick && delete selection.handleClick

        toast.promise(
            db.collection(`shops/${uid}/bookings`).doc(booking.bookingId)
                .update({
                    old_time: booking.time,
                    old_date: booking.date,
                    old_timeString: booking.timeString,
                    old_dateString: booking.dateString,
                    ...selection,
                    timeString: dayjs(selection.time).format('LT'),
                    dateString: `${dayjs(selection.time).format('dddd')} ${dayjs(selection.time).format('Do')} ${dayjs(selection.time).format('MMMM')}`,
                    rescheduled: true,
                    rescheduledAt: Date.now(),
                }),
            {
                loading: 'Rescheduling...',
                success: <b>Booking Rescheduled</b>,
                errror: <b>Sorry, something went wrong</b>,
            }
        );

        setAwaiting(false);
        setModalIsVisible(false);
        close();
    }

    return (
        <>
            <SecondaryBtn
                onClick={() => setModalIsVisible(!modalIsVisible)}
                fullWidth={true}
                style={btnStyles}
            >
                Edit Booking
            </SecondaryBtn>
            <Modal
                isVisible={modalIsVisible}
                shade={true}
                close={
                    JSON.stringify(booking) !== JSON.stringify(selection) ?
                        () => requiresCheck(`Are you sure you cancel this update?`, () => {
                            setModalIsVisible(!modalIsVisible)
                        }) :
                        () => setModalIsVisible(!modalIsVisible)
                }
                title="Update Booking"
                style={{
                    overflow: 'visible'
                }}
            >
                <div
                    style={{
                        marginTop: '1em',
                        display: 'flex',
                        // gap: '1em'
                    }}
                >
                    <Pill
                        style={{
                            flex: '1 1 150px',
                            marginRight: '.5em'
                        }}
                    >
                        <small>
                            Current date:
                        </small>
                        <h4>
                            {booking.dateString}
                        </h4>
                    </Pill>
                    <Pill
                        style={{
                            flex: '1 1 150px'
                        }}
                    >
                        <small>
                            Current time:
                        </small>
                        <h4>
                            {dayjs(booking.time).format("LT")}
                        </h4>
                    </Pill>
                </div>

                <InputContainer>
                    <h3
                        style={{
                            marginBottom: '.2em'
                        }}
                    >Service</h3>
                    <Select
                        options={parseServices}
                        defaultValue={parseServices[currentService]}
                        onChange={handleChange}
                        isSearchable={false}
                    />
                </InputContainer>
                {shopInfo.staff.filter(s => s.disabled !== true).length > 1 ?
                    <InputContainer
                    >
                        <h3
                            style={{
                                marginBottom: '.2em'
                            }}
                        >Staff</h3>
                        <Select
                            options={parseStaff}
                            defaultValue={parseStaff[currentStaff]}
                            onChange={handleChange}
                            isSearchable={false}
                        />
                    </InputContainer> : ''
                }

                <InputContainer>
                    <h3
                        style={{
                            marginBottom: '.2em'
                        }}
                    >Date</h3>
                    <Select
                        options={parseDates}
                        defaultValue={parseDates[currentDate]}
                        onChange={handleChange}
                        isSearchable={false}
                    />
                </InputContainer>

                <InputContainer>
                    <h3
                        style={{
                            marginBottom: '.5em'
                        }}
                    >Time</h3>
                    <Select
                        options={parseTimes}
                        value={currentTime || null}
                        isDisabled={!selection.date}
                        onChange={handleChange}
                        isSearchable={false}
                    />
                </InputContainer>

                <PrimaryBtn
                    onClick={() => requiresCheck(`Are you sure you wish to update this booking?`, () => {
                        handleReschedule()
                    })}
                    disabled={JSON.stringify(booking) === JSON.stringify(selection) || selection.time === null}
                    waiting={awaiting}
                    style={{
                        margin: '1em 0 0 auto',
                    }}
                >
                    Update
                </PrimaryBtn>

            </Modal>
        </>
    )
}

export default RescheduleBooking


const InputContainer = styled.div`
    margin: 1em 0;
    .select{
        position: relative;
        z-index: 0;
    }
    .zyppd__menu{
        position: relative;
        z-index: 0;
    }
`