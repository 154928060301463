import React, { useState, useContext } from 'react';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { AuthContext } from '../Auth/AuthContext'
import styled from 'styled-components'
import SignIn from './SignIn'
import { H2, Title } from 'zyppd-bits';
import CreateAccount from './CreateAccount'
import GoogleButton from 'react-google-button'
const Container = styled.div`
    height: 100vh; 
    width: 100%;
    display: flex; 
    justify-content: center;
    padding: 1em;
`

function NotSignedInLanding() {

    const [state, setState] = useState(true);
    const { businessInfo } = useContext(AuthContext);
    const { businessName } = businessInfo;

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    function googleAuth() {
        signInWithPopup(auth, provider)
            .then((result) => {
            }).catch((error) => {
                console.log(error);
            });
    }


    return (
        <Container>
            <div style={{
                width: '100%',
                maxWidth: '450px',
            }}>
                <BrandHeader
                    style={{
                        margin: '1em 0'
                    }}
                >
                    <Title>{businessName}</Title>
                </BrandHeader>
                <H2>{state ? 'Create Account' : 'Sign In'}</H2>
                <div style={{ marginTop: '.5em', display: 'flex' }}>
                    <p> {state ? "Already" : "Don't"} have an account?</p>
                    <p
                        style={{ margin: '0 .5em', textDecoration: 'underline' }}
                        onClick={() => setState(prevState => !prevState)}
                    >
                        {state ? 'Sign in' : 'Create account'}
                    </p>
                </div>
                <SocialSignIns>
                    <GoogleButton onClick={googleAuth} />
                </SocialSignIns>
                <div
                    style={{ margin: '2em 0' }}
                >
                    {state ?
                        <CreateAccount setState={setState} /> :
                        <SignIn setState={setState} />
                    }
                </div>

            </div>
        </Container>
    )
}

const SocialSignIns = styled.div`
    margin: 1em 0;
`

const BrandHeader = styled.div`
    h1{
        color: ${({ theme }) => theme.brandColor};
    }
`

export default NotSignedInLanding