// console.log(process.env.REACT_APP_DEPLOY)


function functionsURL() {
    const isAbedi = process.env.REACT_APP_ENVIRONMENT === 'abedi';
    console.log({ isAbedi })
    const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod'
    const isDeploy = process.env.REACT_APP_DEPLOY === 'true'

    let functions = ''
    let site = ''
    let stripe_client_id = ''
    let stripe_pk = ''
    let marketing_url = ''

    if (isProduction && isDeploy) {
        functions = 'https://us-central1-booker-65d2a.cloudfunctions.net'
        stripe_client_id = 'ca_HVAiMPcpaXGGhMKa5Ls02XfENhxVDJco'
        stripe_pk = 'pk_live_51GwAJuKFsV4Drq23zysmzzyGGd0ZRnTISwNqIPetNJigvbLCDj1ckUIm5BLV6hDgRqvuhjxC86iM9KCKonlhHM6I00ZU7NBJxM'
        site = 'https://admin.zyppd.in/'
        marketing_url = 'https://zyppd.in'
    }

    if (isProduction && !isDeploy) {
        functions = 'http://localhost:5001/booker-65d2a/us-central1'
        stripe_pk = 'pk_live_51GwAJuKFsV4Drq23zysmzzyGGd0ZRnTISwNqIPetNJigvbLCDj1ckUIm5BLV6hDgRqvuhjxC86iM9KCKonlhHM6I00ZU7NBJxM'
        stripe_client_id = 'ca_HVAiMPcpaXGGhMKa5Ls02XfENhxVDJco'
        site = 'https://admin.zyppd.in'
        marketing_url = 'https://zyppd.in'

    }

    if (!isProduction && !isDeploy) {
        functions = 'http://localhost:5001/zyppd-in-staging/us-central1'
        // functions = 'https://us-central1-zyppd-in-staging.cloudfunctions.net'

        stripe_client_id = 'ca_HVAiTgUvkD3jMllcgx7doCY8OXiXVaLC'
        stripe_pk = 'pk_test_51GwAJuKFsV4Drq23OhifXIcNSx5TwFRQpQf89CL9T09aXyMbJ0bmo4uWmhLlRiyIJSbJ7EkKppjbSGplYkZAiOfd004udS0Z7d'
        site = 'http://localhost:3000'
        marketing_url = 'https://zyppd.in'

    }

    if (!isProduction && isDeploy) {
        site = 'https://admin-zyppd-in-staging.web.app'
        stripe_client_id = 'ca_HVAiTgUvkD3jMllcgx7doCY8OXiXVaLC'
        stripe_pk = 'pk_test_51GwAJuKFsV4Drq23OhifXIcNSx5TwFRQpQf89CL9T09aXyMbJ0bmo4uWmhLlRiyIJSbJ7EkKppjbSGplYkZAiOfd004udS0Z7d'
        functions = 'https://us-central1-zyppd-in-staging.cloudfunctions.net'
        marketing_url = 'https://zyppd.in'
    }

    if (isAbedi && !isDeploy) {
        functions = 'http://localhost:5001/abedi-90475/us-central1'
        site = 'http://localhost:3000'
        stripe_client_id = 'ca_JDqwh0Ia2XQ447hhrsNWLwUDlZbB99fH'
        console.log("localhost for Abedi");
    }

    if (isAbedi && isDeploy) {
        site = 'https://admin.justsync.co.uk/'
        stripe_client_id = 'ca_JDqwh0Ia2XQ447hhrsNWLwUDlZbB99fH'
        functions = 'https://us-central1-abedi-90475.cloudfunctions.net'
        console.log("production for Abedi");

    }



    if (!isProduction) {
        console.log('%cDEVELOPMENT MODE', 'background: #222; color: yellow');
        console.log('%cREMEMBER TO BUILD TO PRODUCTION BEFORE DEPLOYING TO PRODUCTION', 'background: #222; color: #bada55');
        console.log({ functions })
        console.log({ site })
        console.log("isProduction =>", isProduction, "isDeploy =>", isDeploy)
    }


    return { functions, site, stripe_client_id, stripe_pk, marketing_url }

}


export const { functions, site, stripe_client_id, stripe_pk, marketing_url } = functionsURL()

// export default endpoints