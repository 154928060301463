import dayjs from 'dayjs';

const getDays = async ({ numOfDays = 21, shopInfo = {}, staffDaysOff = false, staffName = false, locationId = false }) => {

    if (!Object.entries(shopInfo).length > 0) return [];


    const currentTimeZone = 'Europe/London'
    let today = dayjs()

    const { days, holidays = [] } = shopInfo;

    const isAtChosenLocation = locationId && (locationId !== 'default' || locationId !== 'remote');
    const daysFromShopOrLocation = isAtChosenLocation ? shopInfo.locations.find(loc => loc.id === locationId).days : days

    let hols = holidays.filter(hol => hol.date >= dayjs().tz(currentTimeZone).startOf('d'.valueOf())).map(hol => hol.date)
    let closedDays = daysFromShopOrLocation.filter(day => day.open !== true).map(day => day.day)


    function parseDateObj(day) {

        let title = `${day.format('dddd Do MMMM')}`
        const staffIsOff = staffDaysOff && staffDaysOff.includes(day.day());

        let isDisabled = false;
        let disabledMessage;

        if (staffIsOff) {
            isDisabled = true;
            disabledMessage = `${staffName} is off`
        }

        const isClosed = closedDays.includes(day.day()) || hols.some(hol => day.isSame(hol, 'day'));

        if (isClosed || holidays.includes(day.startOf('day').valueOf())) {
            isDisabled = true;
            disabledMessage = `Closed`
        }

        return ({
            value: day.startOf('d').valueOf(),
            label: isDisabled ? `${title} – ${disabledMessage}` : title,
            name: 'date',
            isDisabled
        })

    }


    let daysArr = []


    for (let i = 0; i < shopInfo.numOfBookingsDays; i++) {
        daysArr.push(parseDateObj(today.add(i, 'day')))
    }

    return daysArr

}

export default getDays