import React, { useState } from 'react'
import { Group, H3 } from 'zyppd-bits'
import { SketchPicker } from 'react-color';
import styled from 'styled-components'
import firebase from 'firebase/compat/app'
import toast from 'react-hot-toast'
export default function Brand({ user, shopInfo }) {

    const [brandColor, setBrandColor] = useState()
    const db = firebase.firestore()

    function selectBrandColor(color) {
        const { hex } = color
        setBrandColor(hex)
        changeBrandColor(hex)
    }

    function changeBrandColor(hex) {
        toast.promise(
            db.collection('shops')
                .doc(user.uid)
                .update({
                    brandColor: hex
                }),
            {
                loading: 'Updating...',
                success: 'Brand colour updated',
                error: 'Sorry, something went wrong'
            }

        )

    }

    function changeTheme(theme) {

        toast.promise(
            db.collection('shops')
                .doc(user.uid)
                .update({
                    themeColors: theme
                }),
            {
                loading: 'Updating...',
                success: 'Theme updated',
                error: 'Sorry, something went wrong'
            }

        )
    }

    const themeOptions = [
        ['#fafafa', 'white'],
        ['whitesmoke', '#EFDBCD'],
        ['#E6AFAC', '#E7D1D2'],
        ['#f9f9f9f9', '#a1bfcf'],
        ['#1a1a1a', '#476e55'],
        ['#666', '#555'],
        ['#3B3A3A', '#1a1a1a'],
        ['#283043', '#191F24'],
    ]
    return (
        <Group>
            <Container>
                <figure style={{ marginBottom: '1em' }}>
                    <H3>Brand Colour</H3>
                    <div
                        style={{
                            margin: '1em 0',
                            display: 'flex',
                        }}
                    >
                        <SketchPicker
                            color={brandColor}
                            onChangeComplete={selectBrandColor}
                        />

                    </div>

                </figure>

                <figure style={{ marginBottom: '1em' }}>
                    <H3>Theme</H3>
                    <ThemeHolder>
                        {themeOptions.map((theme, i) => {
                            return (
                                <div
                                    key={i}
                                    onClick={() => {
                                        changeTheme(theme)
                                    }}>
                                    <span style={{ backgroundColor: theme[0] }}></span>
                                    <span style={{ backgroundColor: theme[1] }}></span>
                                </div>

                            )
                        })}
                    </ThemeHolder>
                </figure>
            </Container>

        </Group>
    )
}


const Container = styled.div`
    display: flex; 
    flex-wrap: wrap;
    figure {
        min-width: 250px;
        max-width: 50%;
        flex: 1; 
    }
`

const ThemeHolder = styled.div`
    margin: 1em 0; 
    div{
        display: inline-block;
        background: ${({ theme }) => theme.background};
        padding: .5em;
        margin: .5em;
        border-radius: ${({ theme }) => theme.borderRadius};
    }
    div span {
        display: inline-block;
        height: 25px;
        width: 55px; 
        // margin-bottom: 1em;
        border-radius: ${({ theme }) => theme.borderRadius};
        &:not(:last-child){
            margin-right: .5em;
        }
    }
`