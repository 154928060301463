import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Nav from './Nav'
import AccountSettings from './Account/AccountSettings'
import ShopDetails from './Account/ShopSettings/ShopDetails/ShopDetails'
import Appearance from './Account/ShopSettings/Appearance/Appearance'
import DatesAndTimes from './Account/ShopSettings/DatesAndTimes/DatesAndTimes'
import Services from './Account/ShopSettings/Services/Services'
import Staff from './Account/ShopSettings/Staff/Staff'
import CreatingSubscription from '../Subscription/CreatingSubscription'
import MainScreen from './Bookings/MainScreen'
import LoadingScreen from '../Components/LoadingScreen'
import AllBookings from '../AllBookings/AllBookings'

const Container = styled.section`
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;
    max-width: 900px;
    margin: 0 auto;
    overflow-y: scroll;
    padding: 4em 1em 4em 1em;
    @media(min-width: 600px){
        padding: 4em 1em 4em 1em;

    }
`

const Dashboard = ({ user, signOut, shopInfo, accountValidation, loadError, businessInfo }) => {

    const [error, setError] = useState(false)
    const [connectedAccount, setConnectedAccount] = useState(false)


    useEffect(() => {
        accountValidation && accountValidation.connectedAccount && accountValidation.connectedAccount.statusCode === 403 && setError(true)
        setConnectedAccount(accountValidation.connectedAccount)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopInfo, accountValidation])


    return (
        <Container>
            <Helmet>
                <title>{businessInfo.businessName} admin</title>
            </Helmet>

            <Nav signOut={signOut} shopName={shopInfo.shopName} user={user} />

            {loadError !== true && accountValidation.awaitingResult ?
                <LoadingScreen
                />
                :
                <Switch>

                    <Route exact path="/">
                        <MainScreen
                            user={user}
                            shopInfo={shopInfo}
                            accountValidation={accountValidation}
                            connectedAccount={connectedAccount}
                            error={error}
                            businessInfo={businessInfo}
                        />
                    </Route>

                    <Route exact path="/account">
                        <AccountSettings
                            user={user}
                            shopInfo={shopInfo}
                            connectedAccount={connectedAccount}
                            accountValidation={accountValidation}
                            businessInfo={businessInfo}
                        />
                    </Route>
                    <Route exact path="/shop_details">
                        <ShopDetails
                            user={user}
                            shopInfo={shopInfo}
                            accountValidation={accountValidation}
                        />
                    </Route>

                    <Route exact path="/appearance">
                        <Appearance user={user} shopInfo={shopInfo} />
                    </Route>

                    <Route exact path="/datesandtimes">
                        <DatesAndTimes user={user} shopInfo={shopInfo} />
                    </Route>
                    <Route exact path="/services">
                        <Services user={user} shopInfo={shopInfo} />
                    </Route>
                    <Route exact path="/staff">
                        <Staff user={user} shopInfo={shopInfo} />
                    </Route>
                    <Route exact path="/awaiting_subscription">
                        <CreatingSubscription user={user} />
                    </Route>
                    <Route exact path="/allbookings">
                        <AllBookings user={user} />
                    </Route>
                </Switch>
            }


        </Container>
    )
}


export default Dashboard
