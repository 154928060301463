import React from 'react'
// import { PrimaryBtn, Group, Input, ErrorMessage } from 'zyppd-bits'
import PasswordAndEmail from './PasswordReset'
import PaymentSettings from './PaymentSettings'
import NotificationSettings from './NotificationSettings'
import DisableShop from './DisableShop'
import AccountDetails from './AccountDetails'
import Transactions from './Transactions'
import { SeeUserSubscription } from '../../Subscription/ManageSubscription'

export default function AccountSettings({ user, shopInfo, businessInfo, connectedAccount, accountValidation }) {

    return user && (
        <section>
            <AccountDetails user={user} shopInfo={shopInfo} businessInfo={businessInfo} />
            {shopInfo.membership !== 'beta' || shopInfo.membership !== 'standard' ?
                <SeeUserSubscription user={user} shopInfo={shopInfo} subscription={accountValidation.subscription} />
                : ''
            }
            <PasswordAndEmail user={user} shopInfo={shopInfo} />

            <Transactions user={user} shopInfo={shopInfo} />
            <PaymentSettings user={user} shopInfo={shopInfo} connectedAccount={connectedAccount} />
            <NotificationSettings user={user} shopInfo={shopInfo} />
            {accountValidation.accountIsValid &&
                <DisableShop user={user} shopInfo={shopInfo} accountValidation={accountValidation} />
            }
        </section>
    )
}