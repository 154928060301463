import 'firebase/compat/storage'
import { getStorage, ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";

const storage = getStorage();

const sortImagesAndReturnURL = async (docs) => {

    let sortedBySize = docs.items.sort((a, b) => b.size - a.size)
    let imgs = []

    for await (let img of sortedBySize) {
        let url = await getDownloadURL(ref(storage, img))
        let path = img?.location?.path
        imgs.push({ url, path })
    }

    return imgs
}

export const getImages = async (publicId, getWhat, cb) => {

    let path;

    switch (getWhat) {
        case 'cover':
            path = '/'
            break;
        case 'gallery':
            path = '/galleryImages'
            break;
        case 'galleryImages':
            path = '/galleryImages'
            break;
        case 'logo':
            path = '/logo'
            break;
        default:
            path = ''
    }

    const listRef = ref(storage, `${publicId}${path}`)
    return listAll(listRef)
        .then(async (docs) => {
            const imgs = await sortImagesAndReturnURL(docs)

            cb && cb()
            return imgs
            // return []
        })
}

export const deleteImage = async (image, cb = () => { }) => {

    const deleteRef = ref(storage, image.url)

    deleteObject(deleteRef)
        .then(() => {
            cb({ deleted: true })
            return { deleted: true }
        })
        .catch((err) => {
            cb({ deleted: false })
            return { deleted: false }
        })
}
