import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";

import { functions } from '../HttpFunctions'
export const AuthContext = React.createContext([() => { }, () => { }, {}]);


const AuthContextProvider = ({ children }, props) => {

    const { pathname } = window.location
    const db = firebase.firestore()
    const auth = getAuth();

    const [user, setUser] = useState({})
    const [loadError, setLoadError] = useState(false)
    const [businessInfo, setBusinessInfo] = useState(false)
    const [shopInfo, setShopInfo] = useState({})
    const [accountReady, setAccountReady] = useState(false);
    const [accountValidation, setAccountValidated] = useState({ awaitingResult: true })
    const [errorMessage, setErrorMessage] = useState('')
    const [signedIn, setSignedIn] = useState(false);

    function signIn(email, password) {

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                setUser(userCredential.user)
            })
            .catch((error) => {
                var errorMessage = error.message;
                setErrorMessage(errorMessage)
            });
    }

    function signOut() {
        auth.signOut().then(function () {
            // Sign-out successful.
            setAccountReady(false)
            setUser({})
            setShopInfo({})
        }).catch(function (error) {
            // An error happened.
        });
    }

    async function getShopInfo(user) {

        db.collection(`shops`)
            .doc(user.uid)
            .onSnapshot((doc) => {
                setShopInfo(doc.data())
                check_account_is_valid(user.uid)
            })

    }
    async function getBusinessInfo(user) {

        db.collection(`business_info`)
            .doc('business_details')
            .onSnapshot((doc) => {
                setBusinessInfo(doc.data())
            })

    }



    useEffect(() => {
        getBusinessInfo();

        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdTokenResult()
                    .then(idTokenResult => {
                        const role = idTokenResult.claims.role || 'admin'
                        setUser({ ...user, role })
                        check_account_is_valid(user.uid)
                    })

                getShopInfo(user)

            } else {
                // User is signed out.
                setSignedIn(false);
                setAccountReady(false);
                setUser({})
                setShopInfo({})
            }
        });


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let accReady = shopInfo && !!Object.keys(shopInfo).length
        //Set account ready to true if a document has been fetched
        setAccountReady(accReady)
        user.uid && setSignedIn(!!Object.entries(user).length)
        user.uid && check_account_is_valid(user.uid)
    }, [shopInfo, user, pathname])

    async function check_account_is_valid(uid) {
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                shopId: uid
            })
        };


        try {
            const api = await fetch(`${functions}/app/check_account_is_valid`, params);
            const response = await api.json()
            setAccountValidated(response)
        }
        catch (error) {
            if (error.name === 'TypeError') {
                console.log('There was a network error.');
                setLoadError(true);
                // setError(true)
            }
            return error
        }
    }

    return (

        <AuthContext.Provider value={{ signIn, signOut, user, errorMessage, shopInfo, accountValidation, loadError, signedIn, accountReady, businessInfo }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider