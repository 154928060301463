import React, { useEffect } from 'react'
import { Spinner } from 'zyppd-bits'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import useAccountValidation from '../AccountValidation/checkAccount'

export default function CreatingSubscription({ user, shopInfo }) {

    const db = firebase.firestore();

    const accountValidation = useAccountValidation({ user, shopInfo })

    const listenForAccountChange = () => {

        db.collection('shops').doc(user.uid)
            .onSnapshot(function (doc) {
                if (doc.data().membership !== 'standard') window.location.assign("/")
            });

    }

    useEffect(() => {
        listenForAccountChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                height: "80vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            {accountValidation.isSubscribed && <Redirect to="/" />}
            <h3
                style={{
                    marginBottom: '1em'
                }}
            >Creating your subscription</h3>
            <Spinner />
        </div>
    )
}