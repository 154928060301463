import React, { useState, useEffect } from 'react'
import { Group, Input, TextArea, PrimaryBtn, Checkbox, Message, useCheck, H2 } from 'zyppd-bits'
import firebase from 'firebase/compat/app'
import toast from 'react-hot-toast'

export default function ShopDetails({ user, shopInfo, accountValidation }) {

    const db = firebase.firestore()
    const { requiresCheck } = useCheck()
    const [error, setError] = useState(false)
    const [update, setUpdate] = useState({})
    const [instagram, setInstagram] = useState(shopInfo.instagram || undefined)
    const [facebook, setFacebook] = useState(shopInfo.facebook || undefined)
    const [twitter, setTwitter] = useState(shopInfo.twitter || undefined)
    const [addressHidden, setAddressHidden] = useState(shopInfo.addressHidden || false)
    const [worksMobile, setWorksMobile] = useState(shopInfo.remote || false)
    const [areasServed, setAreasServed] = useState(shopInfo.areasServed || [])

    const [address, setAddress] = useState({
        line_1: shopInfo.address.line_1 || false,
        line_2: shopInfo.address.line_2 || false,
        city: shopInfo.address.city || false,
        postcode: shopInfo.address.postcode || false,
    })

    useEffect(() => {
        setError(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressHidden, address, update, areasServed])

    useEffect(() => {

        addressHidden !== shopInfo.addressHidden && toast.promise(
            db.collection('shops').doc(user.uid).update({ addressHidden }),
            {
                loading: 'Updating...',
                success: addressHidden ? <b>Your address will now be hidden</b> : <b>Your address will now be shown</b>,
                error: <b>Sorry, something went wrong</b>,
            }

        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressHidden])

    useEffect(() => {
        worksMobile !== shopInfo.remote && toast.promise(
            db.collection('shops').doc(user.uid).update({ remote: worksMobile }),
            {
                loading: 'Updating...',
                success: <b>Updated Successfully</b>,
                error: <b>Sorry, something went wrong</b>,
            }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worksMobile])

    function handleSocialUpdate(name, val) {
        if (val.length === 0) return;
        toast.promise(
            db.collection('shops').doc(user.uid).update({ [name]: val }),
            {
                loading: 'Updating...',
                success: <b>Updated Successfully</b>,
                error: <b>Sorry, something went wrong</b>,
            }

        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    function handleChange(e) {
        e = e.target || e
        setUpdate({ name: e.name, value: e.value })
    }

    function handleSocialChange(e) {
        e = e.target || e

        if (e.value.substr(0, 8) === 'https://') e.value = e.value.substr(8, e.value.length)

        if (e.name === 'instagram') {
            setInstagram(e.value)

        } else if (e.name === 'facebook') {

            setFacebook(e.value)
        } else if (e.name === 'twitter') {
            setTwitter(e.value)

        }
    }


    function handleAddressChange(e) {
        e = e.target || e
        setAddress(prevState => {
            return {
                ...prevState,
                [e.name]: e.value
            }
        })
    }

    function handleAreasServedChange(e) {
        e = e.target || e

        let val = e.value.split(', ').map(v => v.trim())
        setAreasServed(prevState => {
            return [
                ...val
            ]
        })
    }

    const handleUpdate = (val) => {

        toast.promise(
            db.collection(`shops`).doc(user.uid)
                .update({
                    [update.name]: update.value
                }),
            {
                loading: 'Updating...',
                success: <b>Updated Succesfully</b>,
                error: <b>Sorry, something went wrong</b>
            }

        )

    }

    const handleAddressUpdate = (val) => {

        toast.promise(
            db.collection(`shops`).doc(user.uid)
                .update({
                    address: { ...address }
                }),
            {
                loading: 'Updating address...',
                success: <b>Address Updated</b>,
                error: <b>Sorry, something went wrong</b>,
            }

        )

    }
    const handleAreasServedUpdate = (val) => {
        let res = areasServed.filter(area => area)
        if (res.length === 0) {
            return setError({
                name: 'areasServed',
                message: "You must have at least one area if you travel to your customers"
            })
        }
        toast.promise(
            db.collection(`shops`).doc(user.uid)
                .update({
                    areasServed
                }),
            {
                loading: 'Updating areas served...',
                success: <b>Areas updated</b>,
                error: <b>Sorry, something went wrong</b>,
            }

        )
    }

    return accountValidation && (
        <>
            <H2>Shop Introduction</H2>
            <Group>
                <Input
                    type="text"
                    value={shopInfo.shopName}
                    name="shopName"
                    message={'Shop Name'}
                    placeholder="Shop Name"
                    validationNeeded={false}
                    handleInput={handleChange}
                    needsEditing={shopInfo.shopName === 'Starter Shop' || shopInfo.shopName === ''}
                />
                <PrimaryBtn
                    disabled={update.name !== 'shopName' || update.value === shopInfo.shopName}
                    style={{
                        margin: '.5em 0',
                        marginLeft: 'auto'
                    }}
                    onClick={handleUpdate}
                >
                    Update Shop Name
                </PrimaryBtn>
                <TextArea
                    type="text"
                    value={shopInfo.shopDescription}
                    name="shopDescription"
                    message={'Shop Description'}
                    placeholder="Shop Description"
                    validationNeeded={false}
                    handleInput={handleChange}
                />
                <PrimaryBtn
                    disabled={update.name !== 'shopDescription' || update.value === shopInfo.shopDescription}
                    style={{
                        margin: '.5em 0',
                        marginLeft: 'auto'
                    }}
                    onClick={handleUpdate}
                >
                    Update Shop Description
                </PrimaryBtn>
            </Group>

            <H2>Location-based or mobile business?</H2>

            <Group>
                <div
                    style={{ margin: '.5em 0' }}
                >
                    <p
                        style={{ margin: '.5em 0 1em 0' }}
                    >
                        Check this box if you travel to your customers, zyppd.in will then ask the customer for their address
                    </p>
                    <Checkbox
                        onClick={() => {
                            // setWorksMobile(!worksMobile)
                            requiresCheck(`Are you sure you wish to switch your shop to ${!worksMobile ? 'working mobile' : 'having a fixed address'}?`, () => setWorksMobile(!worksMobile))
                        }}

                        checked={shopInfo.remote}
                        label={shopInfo.remote ? 'You travel to your customers' : `Do you travel to your customers?`}
                    />
                </div>
                {shopInfo.remote &&
                    <>
                        <Input
                            type="text"
                            value={areasServed.join(", ")}
                            name="areasServed"
                            message={'Which areas do you serve? (Seperate each area with a comma)'}
                            placeholder="South London, West London, Surrey"
                            validationNeeded={false}
                            handleInput={handleAreasServedChange}
                        />
                        {error && error.name === 'areasServed' &&
                            <Message
                                type="negative"
                                fullWidth={true}
                                style={{
                                    margin: '.5em 0'
                                }}
                            >{error.message}</Message>
                        }
                        <PrimaryBtn
                            disabled={
                                shopInfo.areasServed ? areasServed.join(',') === shopInfo.areasServed.join(',')
                                    : areasServed.length === 0
                            }
                            onClick={handleAreasServedUpdate}
                            style={{
                                margin: '.5em 0',
                                marginLeft: 'auto'
                            }}
                        >
                            Update Served Areas
                        </PrimaryBtn>
                    </>
                }
            </Group>

            <H2>Address</H2>
            <Group
                id="address"
            >
                <div
                    style={{ margin: '.5em 0' }}
                >
                    <Checkbox
                        onClick={() => {
                            // requiresCheck(`Are you sure you wish to ${!addressHidden ? 'show' : 'hide'} your address on your shops homepage?`, () => setAddressHidden(!addressHidden))
                            setAddressHidden(!addressHidden)
                        }}
                        checked={shopInfo.addressHidden}
                        label="Hide address"
                    />
                </div>
                <Input
                    type="text"
                    value={shopInfo.address.line_1 || ''}
                    name="line_1"
                    message={'Address Line 1'}
                    placeholder="1"
                    validationNeeded={false}
                    handleInput={handleAddressChange}
                    // needsEditing={!accountValidation.shopDetails.address.line_1}
                    needsEditing={!shopInfo.address.line_1}

                />
                <Input
                    type="text"
                    value={shopInfo.address.line_2 || ''}
                    name="line_2"
                    message={'Address Line 2'}
                    placeholder="London Wall"
                    validationNeeded={false}
                    handleInput={handleAddressChange}
                    needsEditing={!shopInfo.address.line_2}
                />
                <Input
                    type="text"
                    value={shopInfo.address.city || ''}
                    name="city"
                    message={'City'}
                    placeholder="London"
                    validationNeeded={false}
                    handleInput={handleAddressChange}
                    needsEditing={!shopInfo.address.city}

                // needsEditing={!accountValidation.shopDetails.address.city}

                />
                <Input
                    type="text"
                    value={shopInfo.address.postcode || ''}
                    name="postcode"
                    message={'Post Code' || ''}
                    placeholder="AR1 1RA"
                    validationNeeded={false}
                    handleInput={handleAddressChange}
                    // needsEditing={!accountValidation.shopDetails.address.postcode}
                    needsEditing={!shopInfo.address.postcode}

                />
                <PrimaryBtn
                    disabled={
                        address.line_1 === shopInfo.address.line_1 &&
                        address.line_2 === shopInfo.address.line_2 &&
                        address.city === shopInfo.address.city &&
                        address.postcode === shopInfo.address.postcode
                    }
                    style={{
                        margin: '.5em 0',
                        marginLeft: 'auto'
                    }}
                    onClick={handleAddressUpdate}
                >
                    Update Address
                </PrimaryBtn>
            </Group>

            <H2>Social Media</H2>
            <Group
                title="Social Media"
            >
                <Input
                    type="text"
                    value={shopInfo.instagram}
                    name="instagram"
                    message={'Instagram (copy and paste your instagram url into this box)'}
                    placeholder="https://www.instagram.com"
                    validationNeeded={false}
                    handleInput={handleSocialChange}
                />
                <PrimaryBtn
                    style={{
                        margin: '.5em 0',
                        marginLeft: 'auto'
                    }}
                    disabled={instagram === shopInfo.instagram}
                    onClick={() => {
                        handleSocialUpdate('instagram', instagram)
                    }}
                >
                    Update Instagram
                </PrimaryBtn>
                <Input
                    type="text"
                    value={shopInfo.facebook}
                    name="facebook"
                    message={'Facebook (copy and paste your Facebook url into this box)'}
                    placeholder="https://www.facebook.com"
                    validationNeeded={false}
                    handleInput={handleSocialChange}
                />
                <PrimaryBtn
                    style={{
                        margin: '.5em 0',
                        marginLeft: 'auto'
                    }}
                    disabled={facebook === shopInfo.facebook}
                    onClick={() => {
                        handleSocialUpdate('facebook', facebook)
                    }}
                >
                    Update Facebook
                </PrimaryBtn>
                <Input
                    type="text"
                    value={shopInfo.twitter}
                    name="twitter"
                    message={'Twitter (copy and paste your Twitter url into this box)'}
                    placeholder="https://www.twitter.com"
                    validationNeeded={false}
                    handleInput={handleSocialChange}
                />
                <PrimaryBtn
                    style={{
                        margin: '.5em 0',
                        marginLeft: 'auto'
                    }}
                    disabled={twitter === shopInfo.twitter}
                    onClick={() => {
                        handleSocialUpdate('twitter', twitter)
                    }}
                >
                    Update Twitter
                </PrimaryBtn>
            </Group>

            <H2>Terms & Conditions</H2>
            <Group>
                <TextArea
                    type="text"
                    value={shopInfo.termsAndConditions}
                    name="termsAndConditions"
                    message={'Terms & Conditions'}
                    placeholder="Input any terms and conditions you need your customers to agree to here, we'll show this to them before they make any bookings"
                    validationNeeded={false}
                    handleInput={handleChange}
                />
                <PrimaryBtn
                    disabled={update.name !== 'termsAndConditions' || update.value === shopInfo.termsAndConditions}
                    style={{
                        margin: '.5em 0',
                        marginLeft: 'auto'
                    }}
                    onClick={handleUpdate}
                >
                    Update Terms & Conditions
                </PrimaryBtn>
            </Group>
        </>
    )
}