import firebase from 'firebase/compat/app'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
dayjs.extend(isTomorrow)
dayjs.extend(isToday)



function environment(env) {
    if (env === 'dev') {
        return {
            apiKey: "AIzaSyDOcAWThY2K1jULFF0ZIqPjlz40d1sGfEQ",
            authDomain: "zyppd-in-staging.firebaseapp.com",
            databaseURL: "https://zyppd-in-staging.firebaseio.com",
            projectId: "zyppd-in-staging",
            storageBucket: "zyppd-in-staging.appspot.com",
            messagingSenderId: "973808793",
            appId: "1:973808793:web:62aaf21d0db4e5d8aßc0408",
            measurementId: "G-R752SEW6J2"
        }
    }

    if (env === 'prod') {
        return {
            apiKey: "AIzaSyDWlFEBjj9hPEp5aXcfHw21YtQfSAD6q7A",
            authDomain: "booker-65d2a.firebaseapp.com",
            databaseURL: "https://booker-65d2a.firebaseio.com",
            projectId: "booker-65d2a",
            storageBucket: "booker-65d2a.appspot.com",
            messagingSenderId: "954525131489",
            appId: "1:954525131489:web:44513a71d2d929d14c4da0",
            measurementId: "G-0EQJXCB3WZ"
        }
    }

    if (env === 'abedi') {
        return {
            apiKey: "AIzaSyBRF9ibOP96eLbA1SECzxnOPA0eGvqNSyk",
            authDomain: "abedi-90475.firebaseapp.com",
            projectId: "abedi-90475",
            storageBucket: "abedi-90475.appspot.com",
            messagingSenderId: "492451563905",
            appId: "1:492451563905:web:a367ac307185de9c830080",
            measurementId: "G-3B7L747TSH"
        }
    }
}


firebase.initializeApp(environment(process.env.REACT_APP_ENVIRONMENT));

export default firebase