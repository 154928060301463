import React, { useState, useEffect } from 'react'
import { Group, SubtleListItem, Select, SecondaryBtn, H3, H4 } from 'zyppd-bits'
import { CloseRounded as Cross } from '@material-ui/icons'

import getDays from '../../../../Helpers/getDays'
import firebase from 'firebase/compat/app'
import toast from 'react-hot-toast';

export default function Holidays({ shopInfo, user }) {

    const db = firebase.firestore()
    const [holidays, setHolidays] = useState(shopInfo.holidays ? shopInfo.holidays : [])
    const { numOfBookingsDays } = shopInfo;
    const [dates, setDates] = useState([])


    async function getAndSetDates() {
        const d = await getDays({
            numOfDays: numOfBookingsDays,
            shopInfo: shopInfo
        })

        setDates(d);
    }

    useEffect(() => {
        getAndSetDates();
    }, []);

    useEffect(() => {
        setHolidays(shopInfo.holidays ? shopInfo.holidays : [])
    }, [shopInfo])

    useEffect(() => {

        db.collection(`shops`).doc(user.uid)
            .update({ holidays })
            .catch(err => console.log(err))

        // setDates(getDays(numOfBookingsDays, shopInfo, []))
        getAndSetDates()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [holidays])

    const handleDateAdd = (e) => {
        setHolidays(prevState => {
            return [
                ...prevState,
                { title: e.label, date: e.value }
            ]
        })
        toast.success('A new day off has been added');
    }

    const handleRemoveDate = (date) => {
        setHolidays(prevState => {
            return prevState.filter(val => val !== date)
        })
        toast.success('A day off has been removed')
    }

    return (
        <>
            <Group>
                <H3>Upcoming Days Off</H3>
                <div
                    style={{
                        margin: '1em 0'
                    }}
                >
                    {/* <H4
                        style={{
                            margin: '.5em 0'
                        }}
                    >Add a new day off</H4> */}
                    <Select
                        options={dates}
                        onChange={handleDateAdd}
                        isSearchable={false}
                        message="Add a new day off"
                    />
                </div>
                <ul>
                    {holidays.filter(hol => hol.date > new Date().getTime()).sort((a, b) => a.date - b.date).map(holiday => {
                        return (
                            <SubtleListItem>
                                {holiday.title || holiday}
                                <SecondaryBtn
                                    onClick={() => handleRemoveDate(holiday)}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    <Cross style={{ padding: '0', margin: '0' }} />
                                </SecondaryBtn>
                            </SubtleListItem>
                        )
                    })}
                </ul>
            </Group>
        </>
    )
}