
import React from 'react'
import { useCheck } from 'zyppd-bits'
import { CloseRounded as Cross } from '@material-ui/icons'

import { deleteImage } from '../Helpers/imagesHelpers'

export const Picture = ({ imgs, style, deletable = false, deleteCb = () => { } }, props) => {
    const { requiresCheck } = useCheck()

    return imgs.map((img, i) =>
        <div className="img" key={i} style={{ position: 'relative', padding: 0, margin: 0 }}>
            <img
                {...props}
                onClick={deletable ? () => requiresCheck('Are you sure wish to delete this image?', () => deleteImage(img, deleteCb)) : null}
                style={style}
                src={img.url || img}
                alt=""
            />
            {deletable &&
                <div
                    type="warning"
                    style={{
                        position: 'absolute',
                        top: '1.5em',
                        left: '.5em',
                        pointerEvents: 'none',
                        padding: '.2em',
                        zIndex: '15'
                    }}
                >
                    <Cross />
                </div>
            }
        </div >
    )
}