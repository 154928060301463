import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { ListItem, Notification, useWindowSize, H4, H3 } from 'zyppd-bits'
import { MenuOpenRounded as MenuOpen } from '@material-ui/icons'

import { AuthContext } from '../../Auth/AuthContext'

import SelectedBooking from './SelectedBooking'
import dayjs from 'dayjs'
import styled from 'styled-components'


function organiseBookings(bookings, selectedLocation, selectedStaff) {


    let groupedByDays = bookings
        .reduce((r, a) => {
            let val = dayjs(a.time).startOf('d').valueOf()
            r[val] = [...r[val] || [], a];
            return r;
        }, {})


    return groupedByDays
}


export default function ListBookings({ bookings }) {

    const windowSize = useWindowSize();
    const { shopInfo, user } = useContext(AuthContext);

    const [groupedBookings, setGroupedBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState({});
    function handleClick(val) {
        setSelectedBooking(val)
    }

    useEffect(() => {
        setGroupedBookings(organiseBookings(bookings))
    }, [bookings])


    return (
        <>

            <UL
            >
                {
                    groupedBookings && Object.entries(groupedBookings).map(bookingGroup => {

                        const title = dayjs(new Date(Number(bookingGroup[0]))).isToday() ?
                            'Today'
                            : dayjs(new Date(Number(bookingGroup[0]))).isTomorrow() ?
                                'Tomorrow'
                                : dayjs(new Date(Number(bookingGroup[0]))).format('dddd, D MMMM YYYY')

                        return (
                            <div
                                key={title}
                            >
                                <div
                                    style={{ margin: '2em 0' }}
                                    variants={AnimationContainer}
                                    initial="hidden"
                                    animate="show"
                                    exit="hidden"
                                >
                                    <H3>{title}</H3>

                                    {bookingGroup[1].map((singleBooking, i) => {


                                        const { bookingId } = singleBooking

                                        return (
                                            <UpcomingBookingListItem
                                                key={bookingId}
                                                shopInfo={shopInfo}
                                                variants={AnimationItem}
                                                transition={{
                                                    delay: 0.03 * i
                                                }}
                                                {...singleBooking}
                                                windowSize={windowSize}
                                                handleClick={() => handleClick(singleBooking)}
                                            >

                                            </UpcomingBookingListItem>

                                        )
                                    })}

                                </div>
                            </div>
                        )
                    })
                }

            </UL>

            {
                Object.entries(selectedBooking).length > 0 &&
                <SelectedBooking
                    uid={user.uid}
                    shopInfo={shopInfo}
                    isVisible={Object.entries(selectedBooking).length > 0}
                    booking={selectedBooking}
                    close={() => setSelectedBooking({})}
                />
            }


        </>
    )

}


export const UpcomingBookingListItem = (props) => {

    const windowSize = useWindowSize()
    const currentTimeZone = 'Europe/London'
    const { shopInfo } = props;

    let serviceTitle = (props && shopInfo.services.find(service => service.id === props.service)) || 'This service could not be found'
    serviceTitle = props.service_name || serviceTitle.title || "Service name not found"
    const [staffMember, setStaffMember] = useState(false)

    useEffect(() => {
        const staff = shopInfo.staff && props.staffId && props.staffId !== '000' && shopInfo.staff.find(staff => staff.id === props.staffId)
        const st = shopInfo.staff && shopInfo.staff.filter(st => st.disabled !== true).length
        staff && st && setStaffMember(staff.title)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopInfo, props])


    return props && (
        <ListItem
            className={`${staffMember ? `has-staff` : ''}`}
            variants={AnimationItem}
            onClick={() => props.handleClick({ ...props, serviceTitle, staffMember })}
        >
            {!props.paid && <Notification
                type="warning"
                message="This booking has not been paid"
            />
            }

            <div className='time'>
                <H4>{dayjs(props.time).tz(currentTimeZone).format('LT')}</H4>
            </div>
            <div className='staff'>
                {staffMember && staffMember !== 'No Staff' &&
                    <H3>{staffMember[0]}</H3>
                }
            </div>
            <div className="customer-name">
                <small>Customer Name</small>
                <H4>{props.customer_name}</H4>
            </div>
            {
                windowSize.width > 600 &&
                <div className="service">
                    <small>Service</small>
                    <H4>{serviceTitle}</H4>
                </div>
            }
            {
                windowSize.width > 800 &&
                <div className="booking-id">
                    <small>Booking Id</small>
                    <H4>{props.publicBookingId}</H4>
                </div>
            }


            <MenuOpen />

        </ListItem >
    )
}





export const AnimationContainer = {
    hidden: {
        transition: {
            type: 'tween',
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    show: {
        transition: {
            type: 'tween',
            staggerChildren: 0.1
        }
    }
}

export const AnimationItem = {
    show: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    hidden: {
        y: 20,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }

}


export const UL = styled(motion.ul)`
    margin: 0;
    position: relative;
    z-index: 3;
    svg {
        min-height: 25px;
        min-width: 25px;
    }
    button{
        margin-left: auto;
    }
    li {
        display: grid; 
        grid-gap: .5em;
        grid-template-columns: auto 2em  3fr 30px;
        @media(min-width: 600px){
            grid-template-columns: auto 3em 3fr 3fr 1fr;      
        }
        @media(min-width: 800px){
            grid-template-columns: 2fr 1fr 3fr 3fr 3fr 1fr;
        }

        &.has-staff{
            .staff{
                border-radius: calc(${({ theme }) => theme.borderRadius} * 2); 
                background: ${({ theme }) => theme.middleground};  
                padding: .5em; 
                display: flex; 
                place-items: center;
                justify-content: center;
                align-items: center;
                @media(min-width: 600px){ 
                    margin: 0 .5em;
                }
            }
        }
    }

  
    .time{
        border-radius: ${({ theme }) => theme.borderRadius}; 
        padding: 1em 0;
        text-align: center;
    }
    
   
    .staff{
       
    }
        
    .service h3{
        @media(max-width: 500px){
            max-width: 100px;
        }
        flex: 1;
        display: block;
        border: 1px solid pink;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`