import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import StripeDashboardBtn from '../../Components/StripeComponents/StripeDashboardBtn'
import AddBooking from './AddBooking'

import UpcomingBookings from './UpcomingBookings'
import RequiresServerConnection from '../../Helpers/RequiresServerConnection'
import UpdatesNeeded from '../../AccountValidation/UpdatesNeeded'
import { ChevronRightRounded as RightChevron, PriorityHigh as WarningIcon } from "@material-ui/icons"
import { Group, PrimaryBtn, ListItem, H1, H4, H2 } from 'zyppd-bits'
import { Share } from '@material-ui/icons';

import toast from 'react-hot-toast'

function MainScreen({ shopInfo, accountValidation, connectedAccount, user, error, businessInfo }) {

    const [numOfUpcomingBookings, setNumOfUpcomingBookings] = useState(false);
    return (

        <>
            {shopInfo &&
                <>

                    {accountValidation && !accountValidation.accountIsValid &&
                        <UpdatesNeeded
                            user={user}
                            shopInfo={shopInfo}
                            accountValidation={accountValidation}
                            businessInfo={businessInfo}
                        />
                    }
                    {shopInfo.disabled &&
                        <Link to="/account">
                            <ListItem
                                type="warning"
                            >
                                <WarningIcon />
                                Your shop is disabled: Go live
                                <RightChevron />
                            </ListItem>
                        </Link>
                    }

                    {!error && connectedAccount && !connectedAccount.account.payouts_enabled && shopInfo.stripe_id &&

                        <StripeDashboardBtn
                            fullWidth={true}
                            listItem={true}
                        />
                    }

                    {shopInfo.hasBeenActive &&
                        <UpcomingBookings
                            uid={user.uid}
                            shopInfo={shopInfo}
                            setNumOfUpcomingBookings={setNumOfUpcomingBookings}
                        />
                    }

                    {numOfUpcomingBookings === 0 &&
                        <ShareBox url={shopInfo.url} shopInfo={shopInfo} businessInfo={businessInfo} />
                    }

                    {shopInfo.active && !shopInfo.disabled &&
                        <>
                            <AddBooking user={user} shopInfo={shopInfo} />
                            {navigator.share &&
                                <ShareBox url={shopInfo.url} shopInfo={shopInfo} businessInfo={businessInfo} small={true} />
                            }
                        </>
                    }


                </>
            }
        </>

    )
}


const ShareContainer = styled.figure`
    display: flex;
    align-items: center;
    padding: 4em 0em;
    div {
        flex: 1 1 auto;
    }
`

const ShareBox = ({ url, shopInfo, businessInfo, small = false }) => {

    const { shopName } = shopInfo;



    return small ?
        (
            <CopyToClipboard text={`${businessInfo.domain_name}/${shopInfo.url}`}
                onCopy={() => toast.success("URL copied")}>
                <PrimaryBtn style={{
                    position: 'fixed',
                    bottom: '2em',
                    left: 'calc(50% + 5em)',
                    // transform: 'translate(-50%, 0)',
                    margin: '0 auto',
                    padding: '.5em',
                    zIndex: '4'
                }}>
                    <Share />
                </PrimaryBtn>
            </CopyToClipboard >
        )
        : (
            <ShareContainer>
                <div>
                    <figure>
                        <H1>Your shop is live and ready to go</H1>
                        <H4>When you start to receive bookings you will see this space fill up</H4>
                    </figure>
                    <Group>
                        <H2 style={{
                            margin: '.5em 0 .5em 0',
                            textAlign: 'center'
                        }}>
                            Share {shopName} with the world
                        </H2>
                        <CopyToClipboard text={`${businessInfo.domain_name}/${shopInfo.url}`}
                            onCopy={() => toast.success("URL copied")}>
                            <PrimaryBtn fullWidth>
                                Click to copy your shops url
                                <Share />
                            </PrimaryBtn>
                        </CopyToClipboard>

                    </Group>
                </div>

            </ShareContainer>
        )
}

export default RequiresServerConnection(MainScreen)