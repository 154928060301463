import React, { useState } from 'react'
import { PrimaryBtn, Group, Input, Message, Modal, H3 } from 'zyppd-bits'
import toast from 'react-hot-toast';
import { functions } from '../../HttpFunctions'
import NeedsVerification from '../../Components/NeedsVerfication'
import { Lock as Key } from '@material-ui/icons';
import { getAuth, updatePassword } from "firebase/auth";


const auth = getAuth();


export default function PasswordAndEmail({ user, shopInfo }) {

    return (

        <>
            <H3 style={{ marginBottom: '1em' }}>Password</H3>
            <Group>
                {user.providerData[0].providerId === 'password' ?
                    <PasswordReset user={user} shopInfo={shopInfo} />
                    :
                    <p>Your account was created with {user?.providerData[0]?.providerId}, email and password information should be managed through that provider.</p>
                }


            </Group>

        </>
    )
}

function PasswordReset({ user, shopInfo }) {
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const [password, setPassword] = useState(false)
    const [repeatNewPassword, setRepeatNewPassword] = useState([])
    const [waiting, setWaiting] = useState(false)
    const [error, setError] = useState(false)


    async function password_reset_confirmation() {
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: user.email,
                shopId: shopInfo.publicId
            })
        }
        const url = `${functions}/app/password_reset_confirmation`

        try {
            const api = await fetch(url, params);
            const response = await api.json();
            return response
        } catch (error) {
            return error
        }
    }


    function handlePasswordInput(e) {
        setError('')
        setPassword(e.target.value)
    }

    function handleRepeatNewPasswordPasswordInput(e) {
        setError('')
        setRepeatNewPassword(e.target.value)
    }

    function passwordReset(password) {

        return new Promise((resolve, reject) => {
            if (repeatNewPassword === password) {
                setWaiting(true)

                toast.promise(
                    updatePassword(auth.currentUser, password).then(() => {
                        // Update successful.

                        setModalIsVisible(false)
                        password_reset_confirmation() // Send confirmation email
                        resolve("Updated password")
                        setTimeout(_ => {
                            setWaiting(false)
                        }, 1000)

                    }).catch((error) => {
                        // An error ocurred
                        // ...
                        toast.error(error.message)
                        reject(error.message)
                        setError(error.message)
                        setWaiting(false)
                    }),
                    {
                        loading: 'Updating...',
                        success: 'Password updated',
                        error: <b>Sorry, something went wrong</b>,
                    }
                )

            } else {
                reject('Make sure the passwords match')
                setError('Make sure the passwords match')
            }

        })

    }

    return (
        <>

            <PrimaryBtn fullWidth={true} onClick={() => setModalIsVisible(!modalIsVisible)} >
                Change Password
                <Key />
            </PrimaryBtn>

            <Modal
                isVisible={modalIsVisible}
                shade={true}
                close={() => setModalIsVisible(!modalIsVisible)}
                title="Change password"
            >
                <NeedsVerification>
                    <>
                        <Input
                            type="password"
                            placeholder="Enter new password"
                            message="Enter new password"
                            validationNeeded={false}
                            handleInput={handlePasswordInput}
                            name="password_new"
                        />
                        <Input
                            type="password"
                            placeholder="Repeat new password"
                            message="Repeat new password"
                            validationNeeded={false}
                            handleInput={handleRepeatNewPasswordPasswordInput}
                            name="password_new_repeat"
                        />
                        <PrimaryBtn
                            disabled={waiting ? true : false}
                            waiting={waiting ? true : false}
                            fullWidth={true}
                            onClick={() => passwordReset(password)}
                            style={{ margin: '1em 0' }}
                        >
                            Reset Password
                        </PrimaryBtn>
                    </>

                </NeedsVerification>
                {error && <Message type={'negative'} fullWidth={true}>{error}</Message>}
            </Modal>
        </>
    )
}