import React from 'react'
import { Group, H3 } from 'zyppd-bits'
import StripeConnectBtn from '../../Components/StripeComponents/StripeConnectBtn'
import StripeDashboardBtn from '../../Components/StripeComponents/StripeDashboardBtn'
import RequiresServerConnection from '../../Helpers/RequiresServerConnection'

// TODO: Reflect having connected account in UI
function PaymentSettings({ user, shopInfo, connectedAccount }) {

    return (
        <>
            <H3 style={{ marginBottom: '1em' }}>Payout Settings</H3>
            <Group>
                {!shopInfo.stripe_id || !connectedAccount ?
                    <StripeConnectBtn />
                    :
                    <StripeDashboardBtn>
                        Go to Stripe Dashboard
                </StripeDashboardBtn>

                }

            </Group>
        </>
    )
}

export default RequiresServerConnection(PaymentSettings)
