
export const priceToString = (amount) => {
    let res = (amount / 100).toString()
    let price = (amount / 100)

    if (res.length === 1 || res[1].length < 3) {
        // Set the number to two decimal places
        price = price.toFixed(2);
        return price
    } else {
        return price
    }
}