import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Styles/styles.css'

import { motion } from 'framer-motion'
import AuthContextProvider from './Auth/AuthContext'




ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      {process.env.REACT_APP_ENVIRONMENT === 'dev' &&
        <motion.figure style={{
          position: 'fixed',
          zIndex: 100,
          bottom: 0,
          right: 0,
          background: 'lightgrey',
          color: 'whitesmoke',
          borderRadius: '.5em',
          margin: '.5em',
          padding: '.5em'
        }}
          drag
          dragConstraints={{
            top: -100,
            left: -500,
            right: 800,
            bottom: 1000,
          }}
        >
          <h2 style={{ fontWeight: '300' }}>Staging</h2>
        </motion.figure>
      }

      <App />

    </AuthContextProvider>
  </React.StrictMode >,
  document.getElementById('root')
);
