import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import dayjs from 'dayjs'

import { Modal, PrimaryBtn, ListItem, Group } from 'zyppd-bits';

export default function OldBookings({ user }) {

    const db = firebase.firestore()
    const [allBookings, setAllBookings] = useState([]);
    const [calendarValue, setCalendarValue] = useState(false);
    const [calendarIsVisible, setCalendarIsVisible] = useState(false);

    async function fetchBookings(date) {
        console.log(date)
        const uid = user.uid
        const query = db.collection(`shops/${uid}/bookings`).orderBy('time')

        const bookings = await query.get()
            .then(querySnapshot => {
                let data = []
                querySnapshot.forEach(snap => {
                    // console.log(snap.data())
                    data.push(snap.data())
                })
                return data
            });

        setAllBookings(bookings)
        return bookings
    }

    useEffect(() => {
        fetchBookings();
    }, [])

    useEffect(() => {
        setCalendarIsVisible(false)
    }, [calendarValue])

    return (
        <>
            <BtnContainer>
                <PrimaryBtn
                    disabled={calendarValue}
                    onClick={() => setCalendarIsVisible(!calendarIsVisible)}
                >
                    Pick Date
                </PrimaryBtn>

                <PrimaryBtn
                    disabled={!calendarValue}
                    onClick={() => setCalendarValue(false)}
                >
                    Most Recent
                </PrimaryBtn>
            </BtnContainer>

            <Group>
                {allBookings.map(booking => {
                    return (
                        <ListItem>
                            {booking.dateString} {booking.timeString}
                        </ListItem>
                    )
                })}
            </Group>

            <Modal
                title="Pick Date"
                isVisible={calendarIsVisible}
                shade={true}
                close={() => setCalendarIsVisible(false)}
            >
                <CalendarContainer>
                    <Calendar
                        onChange={setCalendarValue}
                        value={calendarValue}
                    />
                </CalendarContainer>
            </Modal>
        </>
    )
}


const BtnContainer = styled.div`
    display: flex; 
    button {
        margin-right: .5em;
    }
`
const CalendarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`