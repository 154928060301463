import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Input, Modal, Message, PrimaryBtn, SecondaryBtn } from 'zyppd-bits'
import { SearchRounded as SearchIcon, TodayRounded as CalendarIcon } from "@material-ui/icons"
import ListBookings from './ListBookings'

import dayjs from 'dayjs'

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import firebase from 'firebase/compat/app'
import { collection, getDocs, orderBy } from "firebase/firestore";

const Container = styled.div`
    position: relative;
    padding: 0; 
    > * {
        margin: 0;
        margin-bottom: 1em;
    }
`


export default function SearchForBooking({ uid, shopInfo, services, staff, handleClick }) {
    const db = firebase.firestore()

    const [allBookings, setAllBookings] = useState([]);
    const [matchedBookings, setMatchedBookings] = useState([]);
    const [displayResults, setDisplayResults] = useState([]);
    const [numOfResults, setNumOfResults] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [dateRange, setDateRange] = useState([])

    const [calendarValue, setCalendarValue] = useState(false);
    const [calendarIsVisible, setCalendarIsVisible] = useState(false);

    async function getAllBookings() {

        const querySnapshot = await getDocs(collection(db, `shops/${uid}/bookings`), orderBy('time'));
        let data = []
        querySnapshot.forEach((doc) => {
            data.push(doc.data())
        });

        data.sort((a, b) => b.time - a.time)
        setAllBookings(data)
    }


    useEffect(() => {
        if (!isVisible) return
        getAllBookings()
    }, [isVisible])



    useEffect(() => {
        setDateRange([dayjs(calendarValue).startOf('day').valueOf(), dayjs(calendarValue).endOf('day').valueOf()])
    }, [calendarValue])

    useEffect(() => {
        return () => setAllBookings([])
    }, [])

    function handleChange(e) {
        const val = e.target.value.toLowerCase()

        const res = allBookings.filter(booking => {
            if (!booking) return false
            return booking.publicBookingId?.toLowerCase().includes(val) || booking.customer_name?.toLowerCase().includes(val) || booking.customer_email?.toLowerCase().includes(val)
        })

        if (res.length === 0) return;
        setNumOfResults(res.length)
        setMatchedBookings(res)
    }

    function search() {
        setDisplayResults(matchedBookings)
    }
    return (
        <Container>

            <PrimaryBtn
                onClick={() => { setIsVisible(!isVisible); setAllBookings([]) }}
                fullWidth
                style={{
                    margin: '1em 0'
                }}
            >
                Find a booking
                <SearchIcon />
            </PrimaryBtn>



            <Modal
                isVisible={isVisible}
                close={() => setIsVisible(!isVisible)}
                shade={true}
                title="Find a booking"
                style={{
                    zIndex: '20',
                    height: '100vh',
                    width: '100vw',
                    overflowY: 'scroll'
                }}
            >
                {/* {calendarIsVisible &&
                    <CalenderContainer>
                        <Calendar
                            onChange={setCalendarValue}
                            value={calendarValue}
                        />
                        <PrimaryBtn
                            onClick={() => setCalendarIsVisible(false)}
                        >
                            Done
                        </PrimaryBtn>
                    </CalenderContainer>
                } */}

                <InputContainer>
                    <Input
                        type="search"
                        placeholder="Search by name, email, or ID"
                        handleInput={handleChange}
                        value=""
                        name="booking_search"
                        style={{ marginLeft: '1em' }}
                    >
                    </Input>
                    <PrimaryBtn
                        style={{ padding: '0' }}
                        onClick={() => search()}
                    >
                        <SearchIcon />
                    </PrimaryBtn>
                    {/* <SecondaryBtn
                        style={{ padding: '0' }}
                        onClick={() => setCalendarIsVisible(true)}
                    >
                        <CalendarIcon />
                    </SecondaryBtn> */}
                </InputContainer>



                {
                    numOfResults ?
                        <Message
                            fullWidth
                            style={{ margin: '1em 0 0 0' }}
                        >
                            {numOfResults} Results
                        </Message> :
                        <></>
                }
                <ListBookings bookings={displayResults} />
            </Modal>


        </Container >
    )
}

const InputContainer = styled.div`
    display: grid;
    grid-gap: .5em;
    grid-template-columns: 8fr 1fr;

`
const CalenderContainer = styled.div`
    position: absolute;
    top: 1em;
    left: 1em;
    right: 1em;
    bottom: 1em;
    z-index: 22;
    padding: .5em;
    background: rgba(255,255,255,0.5);
`