import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../Auth/AuthContext';
import { motion, AnimatePresence } from 'framer-motion'
import { PrimaryBtn, SecondaryBtn, Shade, SubtleListItem, H4 } from 'zyppd-bits'
import { AnimationContainer, AnimationItem } from '../Styles/ListAnimation'

import { MenuRounded as Menu, Person, DashboardRounded as DashboardIcon, SettingsRounded as Settings, Whatshot, Store, Group, Palette, CloseRounded as Close, Schedule, ChevronLeftRounded as ChevronLeft, ChevronRight } from '@material-ui/icons'

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    z-index: 17;    
`


const NavList = styled(motion.ul)`
    background: ${({ theme }) => theme.middleground};
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
    padding-top: 4em;
    height: 100vh;
    min-width: 250px;
    box-shadow:  ${({ theme }) => theme.shadow};
    display: flex; 
    flex-direction: column;
    ${({ shopMenuIsVisible }) => {
        return shopMenuIsVisible && `
        // transform: translate(-20%, 0);
        // opacity: .3;
        `
    }}
`

const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 18; 
    width: 100%;
    padding: .5em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;

`

const Logo = styled.h3`
    color: ${({ theme }) => theme.brandColor};
`


export const Nav = ({ signOut, shopName, user }) => {

    const { businessInfo } = useContext(AuthContext);
    const { businessName } = businessInfo;

    const [navIsVisible, setNavIsVisible] = useState(false)
    const [shopMenuIsVisible, setShopMenuIsVisible] = useState(false)

    function CloseMenu() {
        setNavIsVisible(false)
        setShopMenuIsVisible(false)
    }

    const [isDashboard, setIsDashboard] = useState()

    const location = useLocation();

    useEffect(() => {
        location.pathname.length > 1 ? setIsDashboard(false) : setIsDashboard(true)
    }, [location])

    return (
        <>
            <Header>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{ marginRight: '.5em', padding: '.5em .5em .5em 0' }}
                        onClick={() => {
                            setNavIsVisible(!navIsVisible)
                        }}>
                        {navIsVisible ?
                            <Close />
                            :
                            <Menu />
                        }
                    </div>

                    <AnimatePresence>
                        {!isDashboard &&
                            <motion.div
                                initial={{ scaleX: 0 }}
                                animate={{ scaleX: 1, transformOrigin: 'left' }}
                                exit={{ scaleX: 0 }}
                            >
                                <Link to="/">
                                    <SecondaryBtn>
                                        {/* <ChevronLeft /> */}
                                        <H4>Dashboard</H4>
                                    </SecondaryBtn>
                                </Link>

                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
                <Link to="/">
                    <Logo>{shopName ? shopName : businessName}</Logo>
                </Link>
            </Header>

            <AnimatePresence>
                {navIsVisible &&
                    <Container>
                        <Shade onClick={() => {
                            setNavIsVisible(!navIsVisible)
                            setShopMenuIsVisible(false)
                        }} />

                        <NavList
                            shopMenuIsVisible={shopMenuIsVisible}
                            variants={AnimationContainer}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                        >
                            <Link to="/" onClick={() => CloseMenu()}>
                                <SubtleListItem variants={AnimationItem}
                                    active={location.pathname === '/'}
                                >
                                    <DashboardIcon />
                                    Dashboard
                                </SubtleListItem>
                            </Link>

                            <Link to="/account" onClick={() => CloseMenu()}>
                                <SubtleListItem variants={AnimationItem}
                                    active={location.pathname === '/account'}

                                >
                                    {/* <User /> */}
                                    <Person />
                                    Account
                                </SubtleListItem>
                            </Link>

                            <SubtleListItem
                                variants={AnimationItem}
                                onClick={() => {
                                    setShopMenuIsVisible(true)
                                }}
                                active={
                                    location.pathname === '/staff' ||
                                    location.pathname === '/services' ||
                                    location.pathname === '/datesandtimes' ||
                                    location.pathname === '/appearance'

                                }

                            >
                                <Settings />
                                    Shop Settings
                                <ChevronRight />
                            </SubtleListItem>

                            <SubMenu
                                isVisible={shopMenuIsVisible}
                                closeSubMenu={() => {
                                    setShopMenuIsVisible(false)
                                }}
                                close={() => CloseMenu()}
                            >

                                <Link to="/shop_details">
                                    <SubtleListItem
                                        variants={AnimationItem}
                                        onClick={() => CloseMenu()}
                                        active={location.pathname === '/shop_details'}
                                    >
                                        <Store />
                                                Shop Details
                                        </SubtleListItem>
                                </Link>
                                <Link to="/staff">
                                    <SubtleListItem
                                        variants={AnimationItem}
                                        onClick={() => CloseMenu()}
                                        active={location.pathname === '/staff'}
                                    >
                                        <Group />

                                                Staff
                                        </SubtleListItem>
                                </Link>
                                <Link to="/services">
                                    <SubtleListItem
                                        onClick={() => CloseMenu()}
                                        variants={AnimationItem}
                                        active={location.pathname === '/services'}

                                    >
                                        <Whatshot />
                                        Services
                                        </SubtleListItem>
                                </Link>
                                <Link to="/datesandtimes">
                                    <SubtleListItem
                                        onClick={() => CloseMenu()}
                                        variants={AnimationItem}
                                        active={location.pathname === '/datesandtimes'}

                                    >
                                        <Schedule />
                                            Opening Days & Times
                                        </SubtleListItem>
                                </Link>

                                <Link to="/appearance" >
                                    <SubtleListItem
                                        onClick={() => CloseMenu()}
                                        active={location.pathname === '/appearance'}
                                    >
                                        <Palette />
                                        Themes & Styling
                                    </SubtleListItem>
                                </Link>
                            </SubMenu>

                            {/* <HelpModal user={user} /> */}
                            <Link to="/" style={{ margin: '.5em .5em 1em .5em', marginTop: 'auto' }}>
                                <PrimaryBtn onClick={() => signOut()} style={{ width: '100%' }} type={'warning'}>
                                    <ChevronLeft />
                                    Sign Out
                                </PrimaryBtn>
                            </Link>
                        </NavList>
                    </Container>
                }
            </AnimatePresence>
        </>
    )
}

export default Nav



const SubMenuContainer = styled(motion.div)`
    position: absolute;
    top: calc(${({ top }) => top}px - 4em);
    left: 3em;
    bottom: 0;
    min-width: 100%;
    max-width: 300px
`
const SubMenu = ({ isVisible, children, close, closeSubMenu }) => {

    const ListEl = useRef(null)
    const [topPos, setTopPos] = useState()
    useEffect(() => {
        if (ListEl.current) {
            setTopPos(ListEl.current.previousSibling.offsetTop)
        }
    }, [isVisible])

    return (

        <AnimatePresence>
            {isVisible &&
                <SubMenuContainer
                    ref={ListEl}
                    isVisible={isVisible}
                    variants={AnimationContainer}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    top={topPos}
                >
                    <SubMenuUl>

                        <header
                            onClick={closeSubMenu}
                        >
                            <ChevronLeft />
                        </header>
                        {children}
                    </SubMenuUl>
                </SubMenuContainer>

            }
        </AnimatePresence>
    )
}

const SubMenuUl = styled.ul`
    position: absolute;
    top: 3em;
    left: 0;
    box-shadow: ${({ theme }) => theme.shadow};
    transition: .2s ease-out;
    padding: .5em; 
    border-radius: ${({ theme }) => theme.borderRadius};
    background: ${({ theme }) => theme.middleground};
    
    `