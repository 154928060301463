import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import { Input, Message, PrimaryBtn, Group, H2 } from 'zyppd-bits'
export default function NeedsVerification({ children }) {

    const [provider, setProvider] = useState(firebase.auth().currentUser.providerData[0].providerId);

    useEffect(() => {

        setProvider(firebase.auth().currentUser.providerData[0].providerId);
    }, [])

    const [waiting, setWaiting] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [error, setError] = useState(false)
    const [password, setPassword] = useState(false)

    function handlePasswordInput(e) {
        setError('')
        setPassword(e.target.value)
    }


    const reauthenticate = (currentPassword) => {

        if (provider === 'password') {
            var user = firebase.auth().currentUser;
            var cred = firebase.auth.EmailAuthProvider.credential(
                user.email, currentPassword);

            user.reauthenticateWithCredential(cred).then(function () {
                setWaiting(true)
                setTimeout(() => {

                    setIsAuthenticated(true)
                    setWaiting(false)

                }, 1000)

            }).catch(function (error) {
                setError(error.message)
            });
        } else {
            firebase.auth().currentUser.reauthenticateWithPopup(new firebase.auth.GoogleAuthProvider())
                .then(function (userCredential) {
                    setWaiting(true)
                    setTimeout(() => {

                        setIsAuthenticated(true)
                        setWaiting(false)

                    }, 1000)
                    // You can now delete the user:
                    // return firebase.auth().currentUser.delete();
                })
                .catch(function (error) {
                    // Credential mismatch or some other error.
                });
        }
    }

    return (
        <>
            {!isAuthenticated ?
                <Group>
                    <H2 style={{ margin: '0  0 .5em .5em' }}>First re-authenticate your account</H2>
                    {provider === 'password' ?
                        <>
                            <Input
                                type="password"
                                message="Enter your password"
                                validationNeeded={false}
                                handleInput={handlePasswordInput}
                                name="re_authenticate"
                                useStorage={false}
                            />
                            <PrimaryBtn
                                disabled={waiting ? true : false}
                                waiting={waiting ? true : false}
                                fullWidth={true}
                                onClick={() => reauthenticate(password)}
                                style={{ margin: '1em 0' }}
                            >
                                Re-authenticate
                            </PrimaryBtn>
                            {error && <Message style={{ marginTop: '1em' }} type={'negative'} fullWidth={true}>{error}</Message>}
                            <p style={{ fontSize: '0.8rem', marginTop: '0.5em' }}>If you've forgotten your password, log out and click the 'Forgot password' button</p>
                        </>
                        : <PrimaryBtn
                            onClick={reauthenticate}
                            fullWidth
                        >
                            Re-authenticate
                        </PrimaryBtn>
                    }
                </Group>

                : <div>
                    {children}
                </div>
            }
        </>
    )
}
