import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SubtleListItem, PrimaryBtn, ListItem, Input, useCheck, Message, Spinner } from 'zyppd-bits'
import { ChevronRightRounded as RightChevron, PriorityHighRounded as WarningIcon, ArrowRightRounded as RightArrow } from "@material-ui/icons"

import toast from 'react-hot-toast'
import styled from 'styled-components'
import { SubscriptionOptions } from '../Subscription/ManageSubscription'
import useAccountValidation from './checkAccount'
import firebase from 'firebase/compat/app'
import { getFunctions, httpsCallable } from "firebase/functions";
import StripeConnectBtn from '../Components/StripeComponents/StripeConnectBtn'

export default function UpdatesNeeded({ user, shopInfo, businessInfo }) {

    const accountValidation = useAccountValidation({ user, shopInfo })
    const { subscription, isDisabled } = accountValidation

    return accountValidation && !accountValidation.accountIsValid ? (
        <div
            style={{
                width: '100%',
                margin: '2em 0'
            }}
        >
            <div
                style={{
                    border: '.5px solid #F4D217',
                    padding: '2em 1em 2em 1em',
                    borderRadius: '.25em'
                }}
            >
                {subscription?.isSubscribed &&
                    <div style={{
                        marginBottom: '2em',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <div
                            style={{
                                margin: '0 1em 0 0'
                            }}
                        >
                            <WarningIcon />
                        </div>
                        <div>
                            <h2>{businessInfo.businessName === "zyppd.in" ? "Get zyppd.in" : "Join"}</h2>
                            <p>Some updates are needed for your shop to go live</p>
                        </div>
                    </div>
                }
                {/* <Group> */}

                {subscription?.isSubscribed !== true ?
                    <SubscriptionOptions
                        user={user}
                        shopInfo={shopInfo}
                    />
                    : shopInfo.url === 'temporaryurl' || !shopInfo.url ? <UpdateUrl user={user} businessInfo={businessInfo} />
                        :
                        <ul>

                            {isDisabled &&
                                <Link to="/services">
                                    <ListItem
                                        type="warning"
                                    >
                                        <RightArrow />
                                        Your shop is disabled: enable it and start taking bookings
                                        <RightChevron />
                                    </ListItem>
                                </Link>
                            }
                            {shopInfo.services
                                .filter(s => s.disabled !== true)
                                .every(service => service.title === 'Edit This Service') &&
                                <Link to="/services">
                                    <ListItem
                                        type="warning"
                                    >
                                        <RightArrow />
                                        Add services
                                        <RightChevron />
                                    </ListItem>
                                </Link>
                            }

                            {!shopInfo.shopName &&
                                <Link to="/shop_details">
                                    <ListItem
                                        type="warning"
                                    >
                                        <RightArrow />
                                        Add your shop name
                                        <RightChevron />
                                    </ListItem>
                                </Link>
                            }

                            {!Object.values(shopInfo.address).every(val => val) &&
                                <Link to="/shop_details#address">
                                    <ListItem
                                        type="warning"
                                    >
                                        <RightArrow />
                                        Add your address
                                        <RightChevron />
                                    </ListItem>
                                </Link>
                            }

                            {!accountValidation.connectedAccount &&
                                <StripeConnectBtn
                                    complete={!!accountValidation.connectedAccount}
                                />
                            }

                        </ul>
                }
                {/* </Group> */}

            </div>
            {shopInfo.hasBeenActive !== true &&
                <div
                    style={{
                        marginTop: '2em'
                    }}
                >
                    {subscription.isSubscribed &&
                        <div style={{
                            marginBottom: '2em',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <div
                                style={{
                                    margin: '0 1em 0 0'
                                }}
                            >
                                <WarningIcon />
                            </div>
                            <div>
                                <h2>Optional updates</h2>
                                {/* <p>Some updates are needed for your shop to go live</p> */}
                            </div>

                        </div>
                    }

                    <OptionalLink>

                        <Link to="/datesandtimes">
                            <SubtleListItem>
                                <RightArrow />
                                Update your opening days & times
                                <RightChevron />
                            </SubtleListItem>
                        </Link>

                        <Link to="/staff">
                            <SubtleListItem>
                                <RightArrow />
                                Add staff members
                                <RightChevron />
                            </SubtleListItem>
                        </Link>

                        <Link to="/appearance">
                            <SubtleListItem>
                                <RightArrow />
                                Update your branding & images
                                <RightChevron />
                            </SubtleListItem>
                        </Link>

                    </OptionalLink>
                </div>
            }
        </div >

    ) : <div
        style={{
            minHeight: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <Spinner />
    </div>
}

const OptionalLink = styled.div`
    li{
       margin: .5em 0;
    }  
`

function UpdateUrl({ user, businessInfo }) {

    const functions = getFunctions();

    const db = firebase.firestore()
    const [url, setUrl] = useState(false);
    const [awaiting, setAwaiting] = useState(false)
    const [error, setError] = useState(false)
    const [takenUrls, setTakenUrls] = useState([]);

    const { requiresCheck } = useCheck()


    useEffect(() => {
        const urls = httpsCallable(functions, 'subscription-getAllTakenShopUrls')
        urls()
            .then(res => setTakenUrls(res.data))
            .catch(err => console.log(err))

    }, [user])

    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <p style={{
                    textAlign: 'right'
                }}>{businessInfo.domain_name}/</p>
                <Input
                    type="url"
                    name={"url"}
                    placeholder={"yourshopurl"}
                    fullWidth
                    style={{
                        width: '100%'
                    }}
                    // message="Create your shops URL, this can only be done once so choose wisely"
                    handleInput={(e) => setUrl(e.target.value)}
                >
                </Input>
            </div>
            {error &&
                <Message
                    type="negative"
                    fullWidth={true}
                    style={{ margin: '.5em 0' }}
                >
                    {error}
                </Message>
            }
            <PrimaryBtn
                style={{
                    margin: '.5em 0 .5em auto',
                }}
                fullWidth
                onClick={() => {
                    const parsedUrl = url && url.replace(/ /g, '').toLowerCase()
                    if (takenUrls.includes(parsedUrl)) return setError("This url has already been taken, please choose something different");
                    if (!url || parsedUrl.length < 3) return setError("Make sure you've entered a url");

                    requiresCheck(`Your URL will be ${businessInfo.domain_name}/${parsedUrl}, is this correct?`, () => {
                        setAwaiting(true)
                        toast.promise(
                            db.collection('shops').doc(user.uid).update({ url: parsedUrl }),
                            {
                                loading: 'Setting URL...',
                                success: `Your URL has been updated`,
                                error: 'Your URL could not be updated'
                            }
                        )

                    })
                }}
            >
                Update URL
            </PrimaryBtn>
        </>
    )
}
