import React, { useState, useEffect, useContext } from 'react'

import { PrimaryBtn, Notification, ListItem } from 'zyppd-bits'
import { AuthContext } from '../../Auth/AuthContext'
import { ChevronRightRounded as RightChevron, PriorityHighRounded as WarningIcon } from '@material-ui/icons'
import { functions } from '../../HttpFunctions'

export default function StripeConnectBtn(props) {

    const [awaiting, setAwaiting] = useState(false)
    const { shopInfo } = useContext(AuthContext)

    async function stripe_dashboard() {

        setAwaiting(true)

        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                stripe_id: shopInfo.stripe_id

            })
        };

        try {

            const api = await fetch(`${functions}/payments-payments/stripe_dashboard`, params);
            const response = await api.json()
            setAwaiting(false)
            window.location.href = response.url
            return response

        }
        catch (error) {
            console.log('error =>', error)
            setAwaiting(false)
            return error
        }
    }

    const [verificationNeeded, setVerificationNeeded] = useState(false)

    async function get_connected_account() {

        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                stripe_id: shopInfo.stripe_id
            })
        };


        try {
            const api = await fetch(`${functions}/payments-payments/get_connected_account`, params);
            const response = await api.json()

            return response

        }
        catch (error) {
            console.log('error =>', error)
            return error
        }
    }

    async function getConnectedAccout() {
        const acc = await get_connected_account()

        if (!acc.account) return acc
        setVerificationNeeded(!acc.account.payouts_enabled)
        return acc

    }

    useEffect(() => {
        getConnectedAccout()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return props.listItem ?
        (
            <ListItem
                type="warning"
                onClick={() => {
                    stripe_dashboard()
                }}
            >
                <WarningIcon />
                Payout updates needed
                <RightChevron />
            </ListItem>
        )
        : (

            <PrimaryBtn
                fullWidth={true}
                waiting={awaiting}
                disabled={awaiting}
                onClick={() => {
                    stripe_dashboard()
                }}
            >
                {verificationNeeded &&
                    <Notification
                        type="warning"
                        message="Verification Needed"
                    />
                }
                Go to Stripe dashboard
                <RightChevron />
            </PrimaryBtn>

        )
}