/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Select } from 'zyppd-bits'

export default function SelectStaff({ staff, setSelectedStaff, selectedStaff, selectedLocation = false }) {

    const [staffOptions, setStaffOptions] = useState([])
    const [value, setValue] = useState(null);

    const locationChosen = selectedLocation === 'false' ? false : !!selectedLocation;

    const filteredStaff = [
        {
            value: false,
            label: 'All Staff',
            name: 'staff',
            location: 'all'
        },
        ...staff.filter(member => member.disabled !== true)
            .map(member => {
                return {
                    value: member.id,
                    label: member.title,
                    name: 'staff',
                    location: member.location
                }
            })];


    const handleChange = (e) => {
        setValue(e)
        setSelectedStaff(e.value)
        localStorage.setItem('viewStaff', e.value)
    }

    useEffect(() => {

        const onlyStaffAtSelectedLocation = filteredStaff.filter(member => locationChosen ? (member.location === selectedLocation || member.location === 'all') : true)
        setStaffOptions(onlyStaffAtSelectedLocation)
    }, [selectedLocation])

    useEffect(() => {
        setValue(filteredStaff.find(sta => sta.value === selectedStaff))
    }, [selectedStaff])

    useEffect(() => {

        const storedStaff = localStorage.getItem('viewStaff')
        setSelectedStaff(storedStaff)
        setValue(filteredStaff.find(s => s.value.toString() === storedStaff) || filteredStaff[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            style={{
                position: 'relative',
                zIndex: '15'
            }}
            className="filter-item"
        >
            <Select
                isSearchable={false}
                options={staffOptions}
                defaultValue={filteredStaff[0]}
                // defaultValue={staffOptions[staffOptions.findIndex(option => option.value === localStorage.getItem('viewStaff'))]}
                value={value}
                onChange={handleChange}
                placeholder="All Staff"
            />
        </div>
    )
}