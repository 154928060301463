import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import styled from 'styled-components'
import { Group, Input, Progress, Message, H3 } from 'zyppd-bits'
import toast from 'react-hot-toast'
import { getImages } from '../../../../Helpers/imagesHelpers'
import { Picture } from '../../../../Components/Picture'

export default function GalleryImages({ user, shopInfo }) {
    const storage = firebase.storage()
    const [galleryImages, setGalleryImages] = useState([])
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState()

    const handleFile = (e) => {
        handleUpload(e.target.files[0])
    }

    const retrieveGalleryImages = async () => {
        const imgs = await getImages(shopInfo.publicId, 'gallery')
        setGalleryImages(imgs)
    }

    useEffect(() => {
        retrieveGalleryImages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    const handleUpload = async (image) => {
        if (!image.type.includes('image')) {
            return setError("Invalid file type, please upload an image")
        } else {
            setError()
        }
        const uploadTask = storage.ref(`${shopInfo.publicId}/galleryImages/${image.name}`).put(image);


        uploadTask.on('state_changed', snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setProgress(progress)
        }, error => {
            console.log(error)

            toast.error(`There was a problem uploading your image`)

        }, (doc) => {
            retrieveGalleryImages()
            toast.success(`Gallery image uploaded`)
        })

    }

    return (
        <Group>



            <H3>Gallery Images</H3>
            <p>Add images to the gallery on your shops home page</p>

            {!galleryImages.length &&
                <Message
                    style={{ margin: '1em 0' }}
                    fullWidth={true}
                >
                    No Images yet
                    <br />
                    Upload images to show potential customers your shop and services.
                </Message>
            }


            <Gallery>
                <Picture
                    imgs={galleryImages}
                    style={{
                        maxHeight: '25vh',
                        width: '100%',
                        marginTop: '1em'
                    }}
                    deletable={true}
                    deleteCb={retrieveGalleryImages}
                />

            </Gallery>

            {galleryImages && galleryImages.length >= 8 ?
                <Message fullWidth={true}>
                    You can have a maximum of 8 gallery images
                    <br />
                    You must delete some images before you can upload more
                </Message>
                :
                <Input type="file" name="file" handleInput={handleFile} />
            }


            {progress > 0 && progress < 100 && <Progress value={progress} max="100"></Progress>}
            {error && <Message fullWidth={true} type='negative'>{error}</Message>}
        </Group>
    )
}

const Gallery = styled.div`
    display: grid; 
    grid-template-columns: repeat(4, 1fr);
    grid-gap: .5em;
    margin: 1em 0;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 200px;
    }
`