import React, { useEffect, useContext, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { Message, ListItem, Spinner } from 'zyppd-bits'
import { ChevronRightRounded as RightChevron } from '@material-ui/icons';

import { DoneRounded as Tick } from '@material-ui/icons';

import { ArrowRightRounded as RightArrow } from '@material-ui/icons'

import { AuthContext } from '../../Auth/AuthContext'
import queryString from 'query-string'
import { stripe_client_id, functions, site } from '../../HttpFunctions'

export default function StripeConnectBtn({ complete }) {

    const location = useLocation();
    const history = useHistory();
    const [awaiting, setAwaiting] = useState(false);
    const { user } = useContext(AuthContext);


    const { code, state } = queryString.parse(location.search)
    async function createStripeAcc() {
        setAwaiting(true)
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: code,
                state: state,
                uid: user.uid
            })
        }

        const url = `${functions}/payments-payments/connect/oauth`
        const response = await fetch(url, params)

        if (response.status === 200) {
            // setAwaiting(false)
            history.push('/')
        }
    }


    useEffect(() => {
        if (code) {
            createStripeAcc()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return awaiting ?
        <div
            style={{
                padding: '1em 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <h4>Connecting your pay out method...</h4>
            <Spinner />
        </div>
        :
        (
            <div>
                {code &&
                    <Message type="warning" style={{ margin: '.5em 0' }} fullWidth={true}>If this message does not dissapear, try waiting 2 minutes and refreshing the page</Message>
                }
                <a href={`https://dashboard.stripe.com/express/oauth/authorize?response_type=code&redirect_uri=${site}${location.pathname}&client_id=${stripe_client_id}&scope=read_write&state=${user.uid}&stripe_user[email]=${user.email}`}>
                    <ListItem
                        fullWidth={true}
                        type="warning"
                    >
                        <RightArrow />
                        Connect a bank to receive payments with Stripe
                    {complete ?
                            <Tick />
                            :
                            <RightChevron />
                        }
                    </ListItem>
                </a>
            </div>
        )
}