import React, { useState } from 'react'
import Holidays from './Holidays'
import styled from 'styled-components'
import { Checkbox, SubtleListItem, Modal, Select, PrimaryBtn, Message, H2, H3, Group } from 'zyppd-bits'
import { AddRounded as EditIcon } from '@material-ui/icons'

import firebase from 'firebase/compat/app'
import toast from 'react-hot-toast'

const advancedBookingOptions = [{
    value: 0,
    label: 'No advanced notice',
    name: 'No advanced notice',
},
{
    value: 60,
    label: '1 Hour',
    name: '1 Hour',
},
{
    value: 120,
    label: '2 Hours',
    name: '2 Hours',
},
{
    value: 6 * 60,
    label: '6 Hours',
    name: '6 Hours',
},
{
    value: 12 * 60,
    label: '12 Hours',
    name: '12 Hours',
},
{
    value: 24 * 60,
    label: '24 Hours',
    name: '24 Hours',
},
{
    value: (7 * 24) * 60,
    label: '7 Days',
    name: '7 Days',
},
{
    value: (30 * 24) * 60,
    label: '30 Days',
    name: '30 Days',
},
]

const numOfBookingsDaysOptions = Array.from({ length: 91 }).map((num, i) => {
    return {
        value: i,
        label: `${i} days`,
        name: `${i} days`,
    }
}).splice(7, 90)

export default function DatesAndTimes({ shopInfo, user }) {

    const db = firebase.firestore();

    let { days } = shopInfo
    const [editTimeModal, setEditTimeModal] = useState(false)
    const [newOpeningTime, setNewOpeningTime] = useState(false)
    const [newClosingTime, setNewClosingTime] = useState(false)
    const [awaiting, setAwaiting] = useState(false)
    const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    function toggleOpen(day, i) {

        const newDays = [...days]
        newDays[i] = { ...day, open: !day.open }
        toast.promise(
            db.collection('shops').doc(user.uid)
                .update({ days: newDays }),
            {
                loading: 'Updating...',
                success: `${daysOfTheWeek[day.day]}s are now ${day.open ? 'closed' : 'open'}`,
                error: 'Sorry, something went wrong'
            }

        )

    }


    function getTimeOptions(type) {
        const d = new Date()
        const year = d.getFullYear()
        const month = d.getMonth()
        const day = d.getDate()

        const MINUTES_IN_A_DAY = 1440
        let times = []
        for (let i = 0; i < MINUTES_IN_A_DAY; i += shopInfo.timeSlotLength) {
            let newTime = new Date(new Date(year, month, day).setMinutes(i))
            let hour = newTime.getHours();
            let minutes = newTime.getMinutes();
            let t = `${hour < 10 ? '0' : ''}${hour}:${minutes}${minutes === 0 ? '0' : ''}`
            times.push({
                value: t,
                label: t,
                name: t,
            })
        }

        newOpeningTime && type === 'last' && times.splice(0, times.findIndex(t => t.value === newOpeningTime) + 1)
        return times
    }

    function updateTimes(dayToUpdate) {

        setAwaiting(true)
        const newDays = days.map(day => {
            return day.day === dayToUpdate.day ? { ...day, firstBookingTime: newOpeningTime, lastBookingTime: newClosingTime } : { ...day }
        })

        toast.promise(
            db.collection('shops').doc(user.uid)
                .update({ days: newDays }),
            {
                loading: 'Updating...',
                success: 'Opening times have been changed',
                error: 'Sorry, something seems to have gone wrong'
            }
        );
        setEditTimeModal(false)
        setNewOpeningTime(false)
        setNewClosingTime(false)
        setAwaiting(false)

    }


    return (
        <>
            <H2>Dates and times</H2>

            <Holidays shopInfo={shopInfo} user={user} />

            <>
                <ul>
                    {days.length > 0 && days.map((day, i) => {

                        return i > 0 && (
                            <SubtleListItem
                                disabled={!day.open}
                            >
                                <Check>
                                    <Checkbox
                                        checked={day.open}
                                        onClick={() => {
                                            toggleOpen(day, i)
                                        }}
                                    />
                                </Check>
                                <h3>{daysOfTheWeek[day.day]}</h3>

                                {day.open ?
                                    <div
                                        style={{
                                            marginLeft: 'auto',
                                            display: 'flex'
                                        }}
                                        onClick={() => setEditTimeModal(day)}
                                    >
                                        <p style={{ marginRight: '1em' }}>{day.firstBookingTime} – {day.lastBookingTime}</p>
                                        <EditIcon />
                                    </div>
                                    :
                                    <Message
                                        type="warning"
                                        style={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            marginRight: '1em'
                                        }}
                                    >Closed</Message>
                                }
                            </SubtleListItem>
                        )
                    })}

                    {days.length > 0 &&
                        <SubtleListItem
                            disabled={!days[0].open}
                        >
                            <Check>
                                <Checkbox
                                    checked={days[0].open}
                                    onClick={() => {
                                        toggleOpen(days[0], 0)
                                    }}
                                />
                            </Check>
                            <h3>{daysOfTheWeek[days[0].day]}</h3>
                            {days[0].open ?
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        display: 'flex'
                                    }}
                                    onClick={() => setEditTimeModal(days[0])}
                                >
                                    <p style={{ marginRight: '1em' }}>{days[0].firstBookingTime} – {days[0].lastBookingTime}</p>
                                    <EditIcon />
                                </div>
                                :
                                <Message
                                    type="warning"
                                    style={{
                                        marginLeft: 'auto',
                                        display: 'flex',
                                        marginRight: '1em'
                                    }}
                                >Closed</Message>
                            }
                        </SubtleListItem>
                    }

                </ul>

                <Group>
                    <H3>Set future dates</H3>
                    <small>Set how far into the future bookings are available.</small>
                    {shopInfo.minimumAdvanceBooking >= shopInfo.numOfBookingsDays * 1440 &&
                        <Message
                            type="warning"
                            style={{
                                margin: '.5em 0',
                            }}
                        >
                            Tip: increase the available dates to ensure your customers can book further into the future than your advanced notice period
                        </Message>
                    }
                    <div
                        style={{
                            margin: '1em 0',
                            position: 'relative',
                            zIndex: '15'
                        }}
                    >
                        <Select
                            defaultValue={numOfBookingsDaysOptions.find(day => day.value === shopInfo.numOfBookingsDays)}
                            options={numOfBookingsDaysOptions}
                            onChange={(e) => {
                                toast.promise(
                                    db.collection('shops').doc(user.uid)
                                        .update({ numOfBookingsDays: e.value }),
                                    {
                                        loading: 'Updating...',
                                        success: 'Future days updated',
                                        error: 'Sorry, something seems to have gone wrong'
                                    }
                                );
                            }}
                            isSearchable={false}
                        />
                    </div>
                </Group>

                <Group>
                    <H3>Set advanced notice</H3>
                    <small>Set a minimum amount of time in which customers must have made their booking before their appointment.</small>
                    <div
                        style={{
                            margin: '1em 0'
                        }}
                    >
                        <Select
                            defaultValue={advancedBookingOptions.find(el => el.value === shopInfo.minimumAdvanceBooking)}
                            options={advancedBookingOptions}
                            onChange={(e) => {
                                toast.promise(
                                    db.collection('shops').doc(user.uid)
                                        .update({ minimumAdvanceBooking: e.value }),
                                    {
                                        loading: 'Updating...',
                                        success: 'Advanced notice updated',
                                        error: 'Sorry, something seems to have gone wrong'
                                    }
                                );
                            }}
                            isSearchable={false}
                        />
                    </div>
                </Group>

                <Modal
                    isVisible={editTimeModal}
                    close={() => setEditTimeModal(false)}
                    shade={true}
                    style={{
                        overflow: 'visible'
                    }}
                    title={`Edit times for ${daysOfTheWeek[editTimeModal.day]}s`}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: '1em'
                        }}
                    >
                        <div
                            style={{
                                flex: '1 1 auto',
                                marginRight: '.5em'
                            }}
                        >
                            <h4 style={{ marginBottom: '.5em' }}>
                                First booking at
                            </h4>
                            <Select
                                options={getTimeOptions('first')}
                                onChange={(e) => setNewOpeningTime(e.value)}
                                isSearchable={false}
                            />
                        </div>
                        <div
                            style={{ flex: '1 1 auto' }}
                        >
                            <h4 style={{ marginBottom: '.5em' }}>
                                Last booking at
                            </h4>
                            <Select
                                options={[...getTimeOptions('last')]}
                                onChange={(e) => setNewClosingTime(e.value)}
                                isSearchable={false}
                            />
                        </div>
                    </div>

                    <PrimaryBtn
                        onClick={() => updateTimes(editTimeModal)}
                        disabled={!newClosingTime || !newOpeningTime}
                        waiting={awaiting}
                    >
                        Update Times
                    </PrimaryBtn>

                </Modal>
            </>
        </>
    )
}

const Check = styled.div`
    svg{
        margin: 0 !important;
    }
`