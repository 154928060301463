import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import { Message, Group, Input, Progress, H3 } from 'zyppd-bits'
import { Picture } from '../../../../Components/Picture'
import toast from 'react-hot-toast'
import { getImages } from '../../../../Helpers/imagesHelpers'

export default function CoverImage({ user, shopInfo }) {

    const storage = firebase.storage()

    const [coverImages, setCoverImages] = useState([])
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState()



    const handleFile = (e) => {
        handleUpload(e.target.files[0])
    }

    const retrieveCover = async () => {

        const imgs = await getImages(shopInfo.publicId, 'cover')
        setCoverImages(imgs)

    }

    useEffect(() => {
        retrieveCover()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleUpload = async (image) => {

        if (!image.type.includes('image')) {
            return setError("Invalid file type, please upload an image")
        } else {
            setError()
        }

        const uploadTask = storage.ref(`${shopInfo.publicId}/cover`).put(image);

        uploadTask.on('state_changed', snapshot => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setProgress(progress)
        }, error => {

            toast.error(`There was a problem uploading your image`)

        }, async (doc) => {
            retrieveCover()
            toast.success(`Image has been uploaded`)
        })

    }


    return (
        <Group>
            <H3>Cover Image</H3>
            <p>The cover image on your shops landing page</p>

            {!coverImages.length > 0 &&
                <Message
                    style={{ margin: '1em 0' }}
                    fullWidth={true}
                >
                    No cover image yet
                    <br />
                    Upload a cover image  to show potential customers your shop and services.
                </Message>
            }

            {coverImages && coverImages.length > 0 &&

                <Picture
                    imgs={coverImages}
                    style={{
                        maxHeight: '25vh',
                        width: '100%',
                        marginTop: '1em',
                        objectFit: 'cover'
                    }}
                    deletable={true}
                    deleteCb={retrieveCover}
                />
            }
            <small>Recommended size: 1200 x 600px</small>


            {coverImages.length > 0 &&
                <p>Uploading a new image will overwrite the previous image</p>
            }

            <Input type="file" name="file" handleInput={handleFile} />
            {progress > 0 && progress < 100 && <Progress value={progress} max="100"></Progress>}
            {error && <Message fullWidth={true} type='negative'>{error}</Message>}


        </Group>
    )
}


