import React, { useState, useContext } from 'react';
import firebase from 'firebase/compat/app'
import { AuthContext } from './AuthContext'
import { PrimaryBtn, Input, Modal, Message } from 'zyppd-bits'
import { ChevronRightRounded as RightChevron } from '@material-ui/icons';
import toast from 'react-hot-toast'

export default function SignIn({ setState }) {

    const [passwordModalIsVisible, setPasswordModalIsVisible] = useState(false)
    const [emailAndPassword, setEmailAndPassword] = useState({
        customer_email: { value: '', isValidated: '' },
        customer_password: { value: '', isValidated: '' },
    })

    const { signIn, errorMessage } = useContext(AuthContext)


    const handleInput = (e, isValidated) => {
        setEmailAndPassword({
            ...emailAndPassword,
            [e.target.name]: {
                value: e.target.value,
                isValidated: isValidated
            }
        })

    }

    return (
        <>

            <>

                <Input
                    type="email"
                    placeholder="Email"
                    name="customer_email"
                    message={'Enter your email'}
                    handleInput={handleInput}
                    validationNeeded={true}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    name="customer_password"
                    message={'Enter your password'}
                    handleInput={handleInput}
                    validationNeeded={false}
                />
                <small
                    style={{ margin: '.5em .5em' }}
                    onClick={() => {
                        setPasswordModalIsVisible(!passwordModalIsVisible)
                    }}
                >
                    Forgotton password?
                </small>

                {errorMessage && <Message fullWidth type={"negative"}>{errorMessage}</Message>}

                <PrimaryBtn
                    fullWidth={true}
                    style={{
                        margin: '1em 0 .5em 0'
                    }}
                    onClick={() => signIn(emailAndPassword.customer_email.value, emailAndPassword.customer_password.value)}
                >
                    Sign In
                    <RightChevron />
                </PrimaryBtn>

            </>

            <ForgotPasswordModal isVisible={passwordModalIsVisible} close={() => setPasswordModalIsVisible(!passwordModalIsVisible)} />

        </>
    )
}


function ForgotPasswordModal({ isVisible, close }) {
    const auth = firebase.auth()
    const [email, setEmail] = useState('')
    const [awaiting, setAwaiting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const sendResetEmail = () => {

        setAwaiting(true)
        auth.sendPasswordResetEmail(email).then(function () {
            // Email sent.
            setTimeout(() => {
                toast.success(`Reset password email sent`)
                close()
                setAwaiting(false)
            }, 500)
        }).catch(function (error) {
            setErrorMessage(error.message)
        });
    }

    function handleInput(e, isValidated) {
        setErrorMessage(false)
        isValidated && setEmail(e.target.value)
    }
    return (

        <Modal
            isVisible={isVisible}
            shade={true}
            close={close}
            title="Enter account email"
        >
            <Input
                type="email"
                placeholder="Email"
                name="customer_email"
                handleInput={handleInput}
                validationNeeded={true}
            ></Input>

            <PrimaryBtn
                fullWidth={true}
                disabled={email === '' || awaiting}
                onClick={() => {
                    sendResetEmail()
                }}
                waiting={awaiting}
                style={{ marginTop: '1em' }}
            >
                Send password reset email
                <RightChevron />
            </PrimaryBtn>

            {errorMessage && <Message style={{ marginTop: '1em' }} type={"negative"}>{errorMessage}</Message>}

        </Modal>
    )
}
