import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet'
import { AuthContext } from './Auth/AuthContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { ZyppdComponents, Footer, Message } from 'zyppd-bits'
import NotSignedInLanding from './Auth/NotSignedInLanding'
import Dashboard from './Dashboard/Dashboard'
import LoadingScreen from './Components/LoadingScreen'
import { Toaster } from 'react-hot-toast'
function App() {

  const { shopInfo, businessInfo } = useContext(AuthContext)

  return (

    <ZyppdComponents
      brandColor={shopInfo?.brandColor || businessInfo.brandColor}
      range={shopInfo?.themeColors}
      toastPosition="bottom-center"
    >
      <Helmet>
        <title>{businessInfo?.businessName || ''} Admin</title>
      </Helmet>

      <Router>

        <Mode />
        <Footer business={`${businessInfo.businessName} ltd.`} color={businessInfo.brandColor} />

      </Router>

      <Toaster
        position="bottom-center"
      />

    </ZyppdComponents >

  );
}

export default App;

function Mode() {

  const { signOut, user, shopInfo, accountValidation, loadError, signedIn, accountReady, businessInfo } = useContext(AuthContext)
  const { businessName } = businessInfo;

  return !businessInfo ? <LoadingScreen />

    : !signedIn ?

      <NotSignedInLanding />

      : signedIn && !accountReady ?

        <LoadingScreen>Building your shop...</LoadingScreen>

        : accountReady ?

          <Dashboard
            user={user}
            shopInfo={shopInfo}
            signOut={signOut}
            accountValidation={accountValidation}
            loadError={loadError}
            businessInfo={businessInfo}
          />

          : <LoadError businessName={businessName} />



}

function LoadError({ businessName }) {

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1em'
      }}
    >
      <Message
        type="negative"
      >
        There was an error connecting to the {businessName} servers <br />
            Please refresh the page or try again later
        </Message>
    </div>
  )
}