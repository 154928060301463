import React, { useState } from 'react'
import { useEffect } from 'react'
import { Select } from 'zyppd-bits'

export default function SelectLocation({ locations, setSelectedLocation }) {

    const [value, setValue] = useState(null);


    const [locationOptions] = useState(() => {
        return [
            {
                value: false,
                label: 'All Locations',
                name: 'location',
            },
            ...locations.filter(member => member.disabled !== true).map(member => {
                return {
                    value: member.id,
                    label: member.title,
                    name: 'location',
                }
            })]

    }, [locations])

    const handleChange = (e) => {
        setSelectedLocation(e.value)
        setValue(locationOptions[locationOptions.findIndex(option => option.value === e.value)]);
        localStorage.setItem('viewLocation', e.value)
    }

    useEffect(() => {
        const storedLocation = localStorage.getItem('viewLocation')
        setSelectedLocation(storedLocation);
        setValue(locationOptions.find(option => option.value.toString() === storedLocation) || locationOptions[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            style={{
                position: 'relative',
                zIndex: '15'
            }}
            className="filter-item"
        >
            <Select
                isSearchable={false}
                options={locationOptions}
                defaultValue={locationOptions[locationOptions.findIndex(option => option.value === localStorage.getItem('viewLocation'))]}
                value={value}
                onChange={handleChange}
                placeholder="All Locations"
            />
        </div>
    )
}