import React, { useState } from 'react'

import { SecondaryBtn, PrimaryBtn, Modal, Shade, H2, H3 } from 'zyppd-bits';
import { ChevronRight as RightChevron, ChevronLeft as LeftChevron } from "@material-ui/icons";
import NeedsVerification from '../../Components/NeedsVerfication';
import { functions } from '../../HttpFunctions';
import RescheduleBooking from './RescheduleBooking';

import firebase from 'firebase/compat/app'

export default function Refund({ uid, booking, shopInfo, close }, props) {

    const db = firebase.firestore()

    const { customer_name, customer_email, timeString, dateString, bookingId, publicBookingId, payment_id } = booking
    // const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [awaiting, setAwaiting] = useState(false)

    const [processModal, setProcessModal] = useState(false)
    const [notificationModal, setNotificationModal] = useState({
        isVisible: false
    })



    async function refund() {
        setAwaiting(true)

        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id,
                bookingId,
                publicBookingId,
                shopId: shopInfo.uid,
                timeString,
                dateString,
                customer_name,
                customer_email,
                shop_name: shopInfo.shopName
            })
        };

        try {
            const api = await fetch(`${functions}/payments-payments/refund`, params);
            const response = await api.json()
            return response

        }
        catch (error) {
            return error
        }
    }



    async function handleRefund() {

        const result = await refund()

        if (result.status === 'succeeded') {
            setProcessModal(false)
            // addToast(`Refund successful`, {
            //     appearance: 'positive',
            //     autoDismiss: true,
            // })
            setNotificationModal({
                isVisible: true,
                message: `Charge has been refunded. It may take up to a minute for the booking to be removed from your dashboard`,
                type: 'positive',
                btn_content: 'Close',
                cb: () => {
                    setNotificationModal({ isVisible: false })

                    close()
                }
            })

            return setAwaiting(false)
        }

        if (result.code === 'charge_already_refunded') {
            setProcessModal(false)

            setNotificationModal({
                isVisible: true,
                message: `This charge has already been refunded`,
                type: 'warning',
                btn_content: 'Close',
                cb: () => {
                    setNotificationModal({ isVisible: false })
                }

            })
            return setAwaiting(false)

        } else {
            setProcessModal(false)

            setNotificationModal({
                isVisible: true,
                message: `There was an unknown error refunding this payment, please check your network connection and try again later`,
                type: 'negative',
                btn_content: 'Close',
                cb: () => {
                    setNotificationModal({ isVisible: false })
                }

            })
        }
    }


    function handleCancel() {
        setAwaiting(true)
        setTimeout(() => {
            db.collection(`shops/${uid}/bookings`)
                .doc(booking.bookingId)
                .update({
                    cancelled: true
                })
                .then(() => {
                    setProcessModal(false)
                    setNotificationModal({
                        isVisible: true,
                        message: `This booking has been sucessfully cancelled`,
                        type: 'positive',
                        btn_content: 'Close',
                        cb: () => {
                            setNotificationModal({ isVisible: false })
                            close()
                        }
                    })

                    return setAwaiting(false)

                })
                .catch(err => {
                    setNotificationModal({
                        isVisible: true,
                        message: `There was an unknown error cancelling this booking, please check your network connection and try again later`,
                        type: 'negative',
                        btn_content: 'Close',
                        cb: () => {
                            setNotificationModal({ isVisible: false })
                        }

                    })

                })
        }, 500)
    }

    return (
        <>
            <PrimaryBtn
                // type={'warning'}
                style={{
                    marginTop: 'auto',
                    marginLeft: 'auto'
                }}
                onClick={() => {
                    setProcessModal(true)
                }}
            >
                {booking.paid ? 'Refund' : 'Cancel Booking'}
            </PrimaryBtn>

            {notificationModal.isVisible || processModal ? <Shade onClick={() => {
                setProcessModal(false)
                setNotificationModal({ isVisible: false })
                // setIsAuthenticated(false)
            }}

            /> : null}

            <Modal
                shade={true}
                isVisible={processModal}
                close={() => setProcessModal(false)}
                title={`${booking.paid ? 'Refund' : 'Cancel'} booking`}
            >
                <NeedsVerification>
                    <>
                        <H2>Are you sure you wish to {booking.paid ? 'refund' : 'cancel'} this booking?</H2>

                        <div style={{ margin: '.5em 0' }}>
                            <H3 style={{ marginBottom: '.5em' }}>You can reschedule instead</H3>
                            <RescheduleBooking booking={booking} shopInfo={shopInfo} uid={uid} close={close} />
                        </div>

                        <div
                            style={{
                                margin: '2em 0'
                            }}
                        >
                            <p>This action canot be undone</p>
                            <small>If this results in your account going into negative figures your account may debited to cover the difference.</small>
                            <PrimaryBtn
                                onClick={booking.paid ? () => {
                                    handleRefund()
                                }
                                    : () => {
                                        handleCancel()
                                    }
                                }
                                disabled={awaiting ? true : false}
                                waiting={awaiting ? true : false}
                                style={{ margin: '1em 0' }}
                                type='warning'
                            >
                                {booking.paid ? 'Refund' : 'Cancel'}  Booking
                                <RightChevron />
                            </PrimaryBtn>

                            <SecondaryBtn onClick={() => {
                                setNotificationModal({
                                    isVisible: false,
                                })
                                setProcessModal(false)
                                // setIsAuthenticated(false)
                            }}
                            // fullWidth={true}
                            >
                                <LeftChevron />
                                No, Do not {booking.paid ? 'refund' : 'cancel'} this booking
                            </SecondaryBtn>

                        </div>
                    </>
                </NeedsVerification>
            </Modal>

            <Modal
                // shade={true}
                // style={{
                //     zIndex: '20'
                // }}
                isVisible={notificationModal.isVisible}
            >
                <h3>{notificationModal.title}</h3>
                <p style={{
                    maxWidth: '25ch'
                }}>{notificationModal.message}</p>

                <PrimaryBtn
                    type={notificationModal.type}
                    fullWidth={true}
                    onClick={() => notificationModal.cb ? notificationModal.cb() : null}
                    style={{
                        marginTop: '2em'
                    }}
                >

                    {notificationModal.btn_content}
                </PrimaryBtn>
            </Modal>
        </>
    )
}
